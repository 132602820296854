import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
// import CheckoutForm from './checkout-form';
import PaymentMethodComponent from './PaymentMethod';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_test_51I8sENGgw4UQroOgTI0gWbnDQYcnQc473gorTAWTOl3G9a01cw6JKXBEs0qZmjfdtynSAg9iKHlHDjwhiKlxTHdh00h8NW2xUQ');

const StripePaymentComponent = ({ token, address, userId, getCardData }) => {
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret: '{{CLIENT_SECRET}}',
  // };

  return (
    <Elements stripe={stripePromise}>
      <PaymentMethodComponent token={token} address={address} userId={userId} getCardInfo={getCardData} />
    </Elements>
  );
};

StripePaymentComponent.propTypes = {
  token: PropTypes.string,
  address: PropTypes.object,
  userId: PropTypes.string,
  getCardData: PropTypes.func.isRequired
};

export default StripePaymentComponent;
