// Prod Url
export const HOST = 'https://nathhdr5b9.execute-api.us-east-1.amazonaws.com/Stage/v1';
// Dev Url
// export const HOST = 'https://qi84n9phy8.execute-api.us-east-2.amazonaws.com/Dev/v1';

export const STRIPE_KEY = 'pk_test_51I8sENGgw4UQroOgTI0gWbnDQYcnQc473gorTAWTOl3G9a01cw6JKXBEs0qZmjfdtynSAg9iKHlHDjwhiKlxTHdh00h8NW2xUQ';
export const STRIPE_SECRET_KEY = 'sk_test_51I8sENGgw4UQroOgHyvFxaNF0yZlE4LZhqAUioxf47OAVJN3deMgyFBCgZjbrlCIbyzVP8YYr2s1zFR9SBKREUK400tuhL7Saf';

export const EMAIL_REGEX = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

export const API_LOGIN_USER = `${HOST}/customer/loginUsingEmail`;
export const API_SOCIAL_LOGIN_USER = `${HOST}/customer/customerSocialLogin`;
export const CREATE_USER_NAME = `${HOST}/customer/checkUserNameExist/userName/`;
export const API_REGISTER_USER = `${HOST}/customer/registerCustomer`;
export const API_VALIDATE_OTP_USER = `${HOST}/customer/validateCustomerLoginOtp`;
export const API_LIWESHOWS_LIST = `${HOST}/customer/customerDashboard`;
export const GET_CHAT_HISTORY = `${HOST}/common/getAllWebinarChat/webinarId/`;
export const GET_WEBINAR_DETAILS = `${HOST}/customer/webinarDetails/webinarId/`;
export const GET_PRODUCT_DETAILS = `${HOST}/customer/productDetails/productId/`;
export const GET_CUSTOMER_REVIEW = `${HOST}/customer/getProductReviews/productId/`;
export const GET_ADDRESS = `${HOST}/customer/getCustomerAddress`;
export const DELETE_ADDRESS = `${HOST}/customer/deleteCustomerAddress/addressId/`;
export const ADD_ADDRESS = `${HOST}/customer/createCustomerAddress`;
export const EDIT_ADDRESS = `${HOST}/customer/updateCustomerAddress`;
export const API_STRIPE_PAYMENT = `${HOST}/customer/placeOrder`;
export const API_GET_CUSTOMER_DETAIL = `${HOST}/customer/getCustomerDetails`;
export const API_GET_STORE_RELATED_DATA = `${HOST}/customer/customerPortalDashboard/storeName/`;

// Cart Related API
export const API_GET_ALL_ADDED_CART_DATA = `${HOST}/customer/myCart`;
export const API_ADD_CART_ITEM = `${HOST}/customer/addToCart`;
export const API_CHANGE_CART_QAUNTITY = `${HOST}/customer/changeCartQuantity/cartId/`;
// export const API_REMOVE_CART_QUANTITY = `${HOST}/customer/changeCartQuantity/cartId/`;
export const API_REMOVE_CART_ITEM = `${HOST}/customer/removeFromCart/cartId/`;
export const GET_CART_SUMMARY = `${HOST}/customer/myCartSummary`;
export const API_CREATE_STRIPE_CUSTOMER = `${HOST}/customer/createStripeCustomer`;
// export const API_GET_SAVED_CARDS = 'https://api.stripe.com/v1/customers/customerId/sources?object=card';
