/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { BsPlayCircle } from 'react-icons/bs';
import styles from './productList.module.scss';
import { ProductHLSVideo } from '../../components/video-player/prod-video';
import dummy from '../../assets/icons/dummy-image.jpg';

const IndividualProductDetail = ({ prodDetails, callAddtoCart, buySelectedProduct }) => {
  const [isVideo, setIsVideo] = useState(true);
  const [parameterList, setparameterList] = useState([]);
  const [categoryFields, setCategoryFields] = useState([]);
  // const [selectedSize, setSelectedSize] = useState('');
  const [initialValue, setInitialValue] = useState(0);
  const [selectedCatData, setCategoryData] = useState([]);
  const [selectedCategory, setCategoryListSelect] = useState(null);
  // const [isM3U8, setM3U8] = useState(false);
  const [showPlayControl, setPlayControl] = useState(true);
  useEffect(() => {
    const subVidId = document.getElementById('subVideo');
    if (subVidId) {
      subVidId.onload = () => {
        // console.log('video found');
        setIsVideo(true);
      };

      subVidId.onerror = () => {
        // console.log('error in loading video');
        setIsVideo(false);
      };
    }
    if (subVidId && subVidId.paused) {
      setPlayControl(true);
    }
  }, [showPlayControl]);

  useEffect(() => {
    if (prodDetails && prodDetails.parameterList.length > 0) {
      prodDetails.parameterList.forEach((params) => {
        for (let i = 0; i < params.categoryFields.length; i++) {
          if (params.categoryFields[i].categoryFieldId === prodDetails.categoryFields[i].categoryFieldId) {
            const a = prodDetails.categoryFields[i].selectedValue || [];
            if (!a.includes(params.categoryFields[i].enteredValue.toString())) {
              a.push(params.categoryFields[i].enteredValue.toString());
              // prodDetails.categoryFields[i].selectedValue += params.categoryFields[i].enteredValue;
              prodDetails.categoryFields[i].selectedValue = a;
            }
          }
        }
      });
      // console.log(prodDetails.categoryFields);
      setCategoryFields(prodDetails.categoryFields);
      if (prodDetails.categoryFields.length === 0) {
        const l = { ...prodDetails.parameterList[0] };
        delete l.categoryFields;
        setCategoryListSelect(l);
      }
    }
  }, []);

  useEffect(() => {
    if (prodDetails && prodDetails.parameterList.length > 0) {
      let p = 0;
      prodDetails.parameterList.forEach((params) => {
        if (p === 0) {
          params.categoryFields.forEach((item) => {
            item.active = true;
          });
          p++;
        }
      });
      // console.log(prodDetails.categoryFields);
      setparameterList(prodDetails.parameterList);
    }
  }, []);

  const comparer = (otherArray) => {
    return (current) => {
      return otherArray.filter((other) => {
        return other.categoryFieldId === current.categoryFieldId && other.enteredValue.toString() === current.enteredValue.toString();
      }).length === 0;
    };
  };

  const checkMatchedCategory = (cata, catb) => {
    const catc = [];
    cata.forEach((item) => {
      const obj = {
        categoryFieldId: item.categoryFieldId,
        enteredValue: item.enteredValue.toString()
      };
      catc.push(obj);
    });
    // console.log(catc);
    // console.log(catb);
    const onlyInA = catc.filter(comparer(catb));
    const onlyInB = catb.filter(comparer(catc));
    // console.log(onlyInA);
    // console.log(onlyInB);
    const result = onlyInA.concat(onlyInB);

    if (result.length === 0) {
      return true;
    }

    return false;
  };
  const validatePrice = (item, categoryId) => {
    // setSelectedSize(item);
    const d = [...parameterList];
    let pp = [];
    d.forEach((param) => {
      param.categoryFields.forEach((categ) => {
        if (categ.categoryFieldId === categoryId && categ.enteredValue.toString() === item) {
          categ.active = true;
        } else if (categ.categoryFieldId === categoryId) {
          categ.active = false;
        }
      });
    });
    setparameterList(d);
    pp = [...d];
    const de = [];
    if (pp.length > 0) {
      pp.forEach((dta) => {
        dta.categoryFields.forEach((test) => {
          if (test.active) {
            const ppl = de.find((lps) => lps.categoryFieldId === test.categoryFieldId);
            if (!ppl) {
              const obj = {
                categoryFieldId: test.categoryFieldId,
                enteredValue: test.enteredValue.toString()
              };
              de.push(obj);
            }
          }
        });
      });
      setCategoryData(de);
    }
    setTimeout(() => {
      if (pp.length > 0) {
        for (let m = 0; m < pp.length; m++) {
          const isMatched = checkMatchedCategory(pp[m].categoryFields, de);
          if (isMatched) {
            const ppd = { ...pp[m] };
            delete ppd.categoryFields;
            setCategoryListSelect(ppd);
            break;
          } else {
            setCategoryListSelect(null);
          }
        }
      }
    }, 500);
  };

  const getUniqeArray = (item, categoryId) => {
    // console.log(item);
    const d = [...new Set(item)];
    let isbtnActive = false;
    let de = [];
    if (parameterList.length > 0) {
      de = parameterList.filter((bitem) => (bitem.categoryFields.some((bd) => bd.categoryFieldId === categoryId && bd.active)));
      if (de) {
        isbtnActive = true;
      } else {
        isbtnActive = false;
      }
      if (initialValue === 0) {
        const l = { ...de[0] };
        delete l.categoryFields;
        const lp = [];
        de[0].categoryFields.forEach((ditem) => {
          const obj = {
            categoryFieldId: ditem.categoryFieldId,
            enteredValue: ditem.enteredValue.toString()
          };
          lp.push(obj);
        });
        setCategoryData(lp);
        setCategoryListSelect(l);
        setInitialValue(1);
      }
    }
    if (de.length > 0) {
      return (
        d.map((pt) => (
          <Button type="button" className={isbtnActive && de[0].categoryFields.some((ls) => ls.enteredValue.toString() === pt.toString()) ? `${styles.activebtn}` : ''} onClick={() => validatePrice(pt, categoryId)}>{pt}</Button>
        ))
      );
    }

    return (<></>);
  };

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const addProductToCart = () => {
    callAddtoCart(selectedCatData);
  };

  const buySelectedProductAction = () => {
    buySelectedProduct(selectedCatData);
  };

  const playSubVideo = () => {
    const subVidId = document.getElementById('subVideo');
    if (subVidId) {
      setPlayControl(false);
      subVidId.play();
    }
  };

  // const pauseSubVideo = () => {
  //   const subVidId = document.getElementById('subVideo');
  //   if (subVidId) {
  //     setPlayControl(true);
  //     subVidId.pause();
  //   }
  // };

  return (
    <>
      <div className="w-100" style={{ height: '380px', overflowY: 'auto' }}>
        <div className={styles.productImageVideo}>
          {prodDetails.productVideoList && prodDetails.productVideoList.length > 0 && isVideo && (
          <div className={styles.productVideo}>
            <ProductHLSVideo currentVideo={prodDetails.productVideoList[0]} />
            {showPlayControl && <BsPlayCircle onClick={playSubVideo} />}
            {/* {!showPlayControl && <BsPauseCircle onClick={pauseSubVideo} />} */}
          </div>
          )}
          {prodDetails.productImageList && prodDetails.productImageList.length > 0 && (
            <div className={styles.prodDetailImage}>
              <img className="img-fluid" src={prodDetails.productImageList[0]} onError={handleImageErr} alt="No Inage" />
            </div>
          )}
        </div>
        <div className="col-12">
          <div className="col-12">
            <p className={styles.prodDetailText}>{prodDetails.name}</p>
          </div>
          <div className="col-12">
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <p className={styles.prodDetailprice}>
                US$
                {/* {prodDetails.parameterList[0].price} */}
                {selectedCategory ? selectedCategory.price : prodDetails.parameterList[0].price}
              </p>
              {prodDetails.featured && <span className={`badge bg-dark ${styles.prodBadgesCustom}`}>Featured</span>}
            </div>
          </div>
          <div className="col-12">
            <p className={styles.prodDetailDesc}>{prodDetails.description}</p>
          </div>
          <div className="col-12">
            <p className={styles.prodDetailtags}>
              <span style={{ textTransform: 'none', color: 'black' }}>
                  &nbsp;
                {prodDetails.tags}
              </span>
            </p>
          </div>
          {(prodDetails.commonAttributes.length > 0 || prodDetails.categoryFields.length > 0) && (
            <div className="col-12">
              <h6>Product Items</h6>
              {prodDetails.commonAttributes.map((element) => (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  {element.type !== 'textView' && (
                  <p className={styles.prodDetailtHint}>
                    {element.hint}
                    :
                  </p>
                  )}
                  <p className={styles.prodDetailtHint} style={{ fontWeight: 'normal' }}>{element.enteredValue}</p>
                </div>
              ))}
              {categoryFields.length > 0 && categoryFields.map((element) => (
                <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column' }}>
                  {element.type && (
                    <>
                      <h4 className={styles.prodDetailtHint}>
                        {element.title}
                        :
                      </h4>
                      {element.selectedValue && element.selectedValue.length > 0 && (
                        <p className={styles.buttonList}>
                          {/* {element.selectedValue.map((listData) => (
                            <Button type="button" className={selectedSize === listData ? `${styles.activebtn}` : ''} onClick={() => validatePrice(listData, element.categoryFieldId)}>{listData}</Button>
                          ))} */}
                          {getUniqeArray(element.selectedValue, element.categoryFieldId)}
                        </p>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="col-12 pb-1">
        <div className="row">
          <div className="col-6">
            <button type="button" disabled={!selectedCategory} className={`btn ${styles.customButton}`} onClick={buySelectedProductAction}>Buy Now</button>
          </div>
          <div className="col-6">
            <button type="button" disabled={!selectedCategory} className={`btn ${styles.customButton}`} onClick={addProductToCart}>ADD TO CART</button>
          </div>
        </div>
      </div>

    </>
  );
};

IndividualProductDetail.propTypes = {
  // currenttime: PropTypes.number,
  prodDetails: PropTypes.object,
  callAddtoCart: PropTypes.func.isRequired,
  buySelectedProduct: PropTypes.func
};

export default IndividualProductDetail;
