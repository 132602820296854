import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './cart.module.scss';
import dummy from '../../assets/icons/dummy-image.jpg';

// eslint-disable-next-line no-shadow
const CartCheckoutComponent = ({ cartItems }) => {
  const handleImageError = (e) => {
    e.target.src = dummy;
  };

  return (
    <div className={styles.cartBlock}>
      <div className={styles.itemContainer}>
        {cartItems && cartItems.length > 0 && cartItems.map((store) => (
          <Fragment key={store.merchantId}>
            <div>
              <h4 style={{ fontSize: '16px', fontWeight: '600' }}>{store.merchantStoreName}</h4>
            </div>
            {store.cartProduct && store.cartProduct.length > 0 && store.cartProduct.map((item) => (
              <div className={`row ${styles.itemList} align-items-center`}>
                <div className="col-3">
                  <div className={styles.imageBlock}>
                    <img className={`img-fluid ${styles.prodImage}`} src={item.productImage ? item.productImage[0] : dummy} onError={handleImageError} alt="No Inage" />
                  </div>
                </div>
                <div className="col-6 p-0">
                  <div className={styles.itemDetails}>
                    <h4>{item.productName}</h4>
                    <p>
                      {item.productDescription ? `${item.productDescription}, ` : ''}
                      <br />
                      {item.categoryList && item.categoryList.length > 0 && item.categoryList.map((cat) => (
                        <span>{`${cat.enteredValue}, `}</span>
                      ))}
                    </p>

                  </div>
                </div>
                <div className="col-3 p-0" style={{ textAlign: 'right' }}>
                  {item.price && (
                    <h4 style={{ fontSize: '12px' }}>
                      US$
                      {' '}
                      <b>{item.price.toFixed(2)}</b>
                      {' '}
                    </h4>
                  )}
                </div>
              </div>
            ))}
          </Fragment>
        ))}
        {cartItems.length === 0 && <div className="pl-3" style={{ color: '#666' }}>Add items to your cart.</div>}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  cartItems: state.cart.cartItems,
  myProps: props
});

CartCheckoutComponent.propTypes = {
  cartItems: PropTypes.array

};
export default connect(mapStateToProps, null)(CartCheckoutComponent);
