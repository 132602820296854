/* eslint-disable import/no-cycle */
import { all, call, takeLatest, put } from 'redux-saga/effects';
import axios from '../../utils/config/http';
import UserActionTypes from '../user/user.types';
import CartActionTypes from './cart.types';
import {
  clearCart,
  getCartItems,
  addCartItemSuccess,
  addCartItemFailure,
  getCartItemSuccess,
  getCartItemfailure,
  changeCartQauantityFailure,
  // changeCartQauantitySuccess,
  removeCartItemFailure,
  // removeCartItemSuccess
} from './cart.actions';
import {
  API_GET_ALL_ADDED_CART_DATA,
  API_ADD_CART_ITEM,
  API_CHANGE_CART_QAUNTITY,
  API_REMOVE_CART_ITEM
} from '../../utils/consts/api';

export function* clearCartOnSignOut() {
  localStorage.removeItem('restaurantId');
  yield put(clearCart());
}

export function* onSignOutSuccess() {
  yield takeLatest(UserActionTypes.SIGN_OUT_SUCCESS, clearCartOnSignOut);
}

const getCartDetail = async () => {
  try {
    const res = await axios.get(API_GET_ALL_ADDED_CART_DATA);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* getAllCartDataFromServer() {
  try {
    const response = yield call(getCartDetail);
    if (response.responseCode === 0) {
      yield put(getCartItemSuccess(response));
    } else {
      yield put(getCartItemfailure(response));
    }
  } catch (error) {
    yield put(getCartItemfailure(error));
  }
}

const addCartDetail = async (data) => {
  try {
    const res = await axios.post(API_ADD_CART_ITEM, data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* addCartToServer({ payload }) {
  try {
    const response = yield call(addCartDetail, payload);
    if (response.responseCode === 0) {
      // yield put(getCartItems());
      yield put(addCartItemSuccess(response));
    } else {
      yield put(addCartItemFailure());
    }
  } catch (error) {
    yield put(addCartItemFailure());
  }
}

const removeCartDetail = async (cartId) => {
  try {
    const res = await axios.put(API_REMOVE_CART_ITEM + cartId);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* removeCartFromServer({ payload }) {
  try {
    const response = yield call(removeCartDetail, payload);
    if (response.responseCode === 0) {
      yield put(getCartItems());
    } else {
      yield put(removeCartItemFailure(response));
    }
  } catch (error) {
    yield put(removeCartItemFailure(error));
  }
}

const increaseCartQuantity = async (objData) => {
  try {
    const res = await axios.put(`${API_CHANGE_CART_QAUNTITY}${objData}/action/add`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* getIncreaseCartItemQuantity({ payload }) {
  try {
    const response = yield call(increaseCartQuantity, payload);
    if (response.responseCode === 0) {
      yield put(getCartItems());
    } else {
      yield put(changeCartQauantityFailure(response));
    }
  } catch (error) {
    yield put(changeCartQauantityFailure(error));
  }
}

const reduceCartQuantity = async (objData) => {
  try {
    const res = await axios.put(`${API_CHANGE_CART_QAUNTITY}${objData}/action/remove`);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* getReduceCartItemQuantity({ payload }) {
  try {
    const response = yield call(reduceCartQuantity, payload);
    if (response.responseCode === 0) {
      yield put(getCartItems());
    } else {
      yield put(changeCartQauantityFailure(response));
    }
  } catch (error) {
    yield put(changeCartQauantityFailure(error));
  }
}

export function* callAddCartMethod() {
  yield takeLatest(CartActionTypes.ADD_CART_ITEM, addCartToServer);
}

export function* callRemoveCartMethod() {
  yield takeLatest(CartActionTypes.DELETE_CART_ITEM, removeCartFromServer);
}

export function* callGetAllCartItems() {
  yield takeLatest(CartActionTypes.GET_CART_ITEMS, getAllCartDataFromServer);
}

export function* callAddCartItemQuantity() {
  yield takeLatest(CartActionTypes.INCREASE_CART_QUANTITY, getIncreaseCartItemQuantity);
}

export function* callRemoveCartItemQuantity() {
  yield takeLatest(CartActionTypes.REDUCE_CART_QUANTITY, getReduceCartItemQuantity);
}

export function* cartSagas() {
  yield all([
    call(onSignOutSuccess),
    call(callAddCartMethod),
    call(callRemoveCartMethod),
    call(callGetAllCartItems),
    call(callAddCartItemQuantity),
    call(callRemoveCartItemQuantity),
  ]);
}
