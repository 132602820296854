import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import styles from './cart.module.scss';
import { increaseCartItemQuantity, reduceCartItemQuantity, deleteCartItem } from '../../redux/cart/cart.actions';
import dummy from '../../assets/icons/dummy-image.jpg';
import LoaderTransparentComponent from '../loader-component/loader-transparent';

// eslint-disable-next-line no-shadow
const CartComponent = ({ increaseCartItemQuantity, reduceCartItemQuantity, cartItems, deleteCartItem }) => {
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      setShowLoader(false);
    }
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
  }, [cartItems]);
  const handleImageError = (e) => {
    e.target.src = dummy;
  };
  const AddItemToCart = (item) => {
    setShowLoader(true);
    increaseCartItemQuantity(item.cartId);
  };
  const removeItemFromCart = (item) => {
    setShowLoader(true);
    reduceCartItemQuantity(item.cartId);
  };

  const deleteItemFromCart = (item) => {
    setShowLoader(true);
    deleteCartItem(item.cartId);
  };

  // useEffect(() => {
  //   if (cartItems && cartItems.length > 0) {
  //     // console.log(cartItems);
  //     let t = 0;
  //     cartItems.forEach((item) => {
  //       t += parseInt(item.quantity, 10) * parseFloat(item.priceDetails.itemPrice);
  //     });
  //     setTotalPrice(t.toFixed(2));
  //   }
  // }, [cartItems]);
  console.log(cartItems, 'cartitems');
  return (
    <div className={styles.cartBlock}>
      <div className={styles.itemContainer}>
        {cartItems && cartItems.length > 0 && cartItems.map((store) => (
          <Fragment key={store.merchantId}>
            <div>
              <h4 style={{ fontSize: '16px', fontWeight: '600' }}>{store.merchantStoreName}</h4>
            </div>
            {store.cartProduct && store.cartProduct.length > 0 && store.cartProduct.map((item) => (
              <div className={`row ${styles.itemList} align-items-center`}>
                <div className="col-3">
                  <div className={styles.imageBlock}>
                    <img className={`img-fluid ${styles.prodImage}`} src={item.productImage ? item.productImage[0] : dummy} onError={handleImageError} alt="No Inage" />
                  </div>
                </div>
                <div className="col-6 p-0">
                  <div className={styles.itemDetails}>
                    <h4>{item.productName}</h4>
                    <p>
                      {item.productDescription ? `${item.productDescription}, ` : ''}
                      <br />
                      {item.categoryList && item.categoryList.length > 0 && item.categoryList.map((cat) => (
                        <span>{`${cat.enteredValue}, `}</span>
                      ))}
                    </p>
                    {item.price && (
                    <h4>
                      US$
                      {' '}
                      {(item.price / item.quantity).toFixed(2)}
                      {' '}
                    </h4>
                    )}
                  </div>
                </div>
                <div className="col-3 p-0" style={{ textAlign: 'right' }}>
                  <div className={styles.svgColor}>
                    <BiTrash style={{ cursor: 'pointer' }} onClick={() => deleteItemFromCart(item)} />
                  </div>
                  <h4 style={{ marginBottom: '0px', fontSize: '10px' }}> &nbsp; </h4>
                  <div className={styles.quantityAndAction}>
                    <AiOutlineMinusCircle onClick={() => removeItemFromCart(item)} />
                    {item.quantity}
                    <AiOutlinePlusCircle onClick={() => AddItemToCart(item)} />
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        ))}
        {cartItems.length === 0 && <div className="pl-3" style={{ color: '#666' }}>Add items to your cart.</div>}
        {showLoader && <LoaderTransparentComponent />}
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  cartItems: state.cart.cartItems,
  myProps: props
});

CartComponent.propTypes = {
  increaseCartItemQuantity: PropTypes.func.isRequired,
  reduceCartItemQuantity: PropTypes.func.isRequired,
  deleteCartItem: PropTypes.func.isRequired,
  cartItems: PropTypes.array

};
export default connect(mapStateToProps, { increaseCartItemQuantity, reduceCartItemQuantity, deleteCartItem })(CartComponent);
