/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './listCard.module.scss';
import dummy from '../../assets/icons/dummy-image.jpg';
// import personAdd from '../../assets/icons/personAdd-icon.png';
import { setId } from '../../redux/liweShowsList/list.actions';
import { disconnectCurrentSocket } from '../../utils/config/socket';

const ProductCards = ({ type, count, item, webinarId }) => {
  const dispatch = useDispatch();
  const [showDate, setShowDate] = useState(false);
  const handleSubmit = (item) => {
    disconnectCurrentSocket();
    dispatch(setId(item));
  };
  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const date = new Date(item.webinarStartTime * 1000);
  const newdate = date.toDateString().substring(4);
  const time = date.toTimeString().substring(0, 5);
  const timeString12hr = new Date(`1970-01-01T${time}Z`)
    .toLocaleTimeString('en-US',
      { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });

  useEffect(() => {
    setTimeout(() => {
      setShowDate(!showDate);
    }, 10000);
  }, [showDate]);

  return (
    <div className={`${styles.card} ${item.webinarId === webinarId ? ` ${styles.activeCard}` : ''}`} key={count} onClick={() => handleSubmit(item)}>
      <div className={styles.listImg}>
        <p className={styles.webinarTitle}>{item.webinarTitle}</p>
        <img className={`img-fluid ${styles.mainImage}`} src={item.webinarImageList[0] ? item.webinarImageList[0] : dummy} onError={handleImageErr} alt={type} />
        {item.webinarStatus === 5 && <span className={`badge bg-danger ${styles.statusTag}`}>LIVE</span>}
        {/* <img className={styles.iconTag} src={personAdd} alt="" /> */}
        <p className={styles.webinarTime}>{showDate ? newdate : timeString12hr}</p>
      </div>
    </div>
  );
};

export default ProductCards;
