import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowLeft } from 'react-icons/bs';
import './redg.scss';
import { EMAIL_REGEX } from '../../utils/consts/api';
import { createUserName, clearUsernameState, emailSignupStart, emailSignupFailure, validateOtp } from '../../redux/user/user.actions';

const RedgComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearUsernameState());
  }, '');
  const validUsername = useSelector(
    (state) => state.user.valid
  );
  const message = useSelector(
    (state) => state.user.message
  );

  const isRegisterSuccess = useSelector(
    (state) => state.user.isRedgValidate
  );
  const [inputs, setInputs] = useState({
    userName: '',
    emailId: '',
    password: '',
  });
  const { userName, emailId, password, cnfPassword } = inputs;
  const [otpinputs, setOTPInputs] = useState({
    otpNumber: '',
  });
  const { otpNumber } = otpinputs;
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [isValidcnfpassword, setIsValidcnfpassword] = useState(false);
  const [errorMsgcnfpassword, setErrorMsgcnfpassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const validateEmail = () => {
    if (emailId) {
      if (!EMAIL_REGEX.test(emailId)) {
        setIsValid(false);
        setErrorMsg('Invalid Email Address.');
      } else {
        setIsValid(true);
        setErrorMsg('');
      }
    } else {
      setIsValid(true);
      setErrorMsg('');
    }
  };

  const cnfPasswordFunc = () => {
    if (cnfPassword) {
      if (password !== cnfPassword) {
        setIsValidcnfpassword(true);
        setErrorMsgcnfpassword('Password does not match');
      } else {
        setIsValidcnfpassword(false);
        setErrorMsgcnfpassword('');
      }
    } else {
      setIsValidcnfpassword(false);
      setErrorMsgcnfpassword('');
    }
  };
  const validateUser = () => {
    if (userName) {
      dispatch(createUserName(userName));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (!emailId) {
      setIsValid(true);
      setErrorMsg('');
    }
  };
  const handleOTPChange = (e) => {
    const { name, value } = e.target;
    setOTPInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (userName && emailId && password) {
      dispatch(emailSignupStart(inputs));
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    if (otpNumber) {
      const postData = {
        email: emailId,
        otpnumber: otpNumber
      };
      dispatch(validateOtp(postData));
    }
  };

  const goBacktoPreviousScreen = (e) => {
    e.preventDefault();
    dispatch(emailSignupFailure('otpfailed'));
  };

  return (
    <div>
      {isRegisterSuccess === -1 && (
        <div>
          <form autoComplete="off">
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <input type="email" className="form-control" placeholder="Create Username" name="userName" value={userName} required onChange={handleChange} onBlur={validateUser} />
                  {submitted && !userName && (
                    <div className="text-danger">
                      User name is required
                    </div>
                  )}
                  {validUsername === false && <div className="text-danger">{message}</div>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input type="email" className="form-control" placeholder="Enter email" name="emailId" value={emailId} required onChange={handleChange} onBlur={validateEmail} />
                  {submitted && !emailId && (
                    <div className="text-danger">
                      Email is required
                    </div>
                  )}
                  {!isValid && <div className="text-danger">{errorMsg}</div>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input type="password" className="form-control" placeholder="Enter password" name="password" value={password} required onChange={handleChange} />
                  {submitted && !password && (
                    <div className="text-danger">
                      Password is required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input type="password" className="form-control" placeholder="Confirm password" name="cnfPassword" value={cnfPassword} required onChange={handleChange} onBlur={cnfPasswordFunc} />
                  {submitted && !cnfPassword && (
                    <div className="text-danger">
                      Confirm password is required
                    </div>
                  )}
                  {isValidcnfpassword && <div className="text-danger">{errorMsgcnfpassword}</div>}
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="btn customButton" disabled={!userName || !emailId || !password || !cnfPassword || !isValid || isValidcnfpassword || validUsername === false} onClick={handleSubmit}>Sign up</button>
              </div>
            </div>
          </form>
        </div>
      )}
      {isRegisterSuccess === 0 && (
        <div>
          <form autoComplete="off">
            <div className="row">
              <div className="col-12">
                <BsArrowLeft className="validateOtpArrow" onClick={goBacktoPreviousScreen} />
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input type="email" className="form-control" placeholder="Enter email" name="emailId" value={emailId} readOnly />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Enter passcode" name="otpNumber" value={otpNumber} required onChange={handleOTPChange} />
                </div>
              </div>
              <div className="col-12">
                <button type="button" className="btn customButton" disabled={!otpNumber} onClick={handleOTPSubmit}>Validate</button>
              </div>
            </div>
          </form>

        </div>
      )}
    </div>
  );
};

export default RedgComponent;
