import React, { useState, useEffect } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import styles from './cart.module.scss';
import CartComponent from '../../components/Cart/cart';
import { getCartItems } from '../../redux/cart/cart.actions';
import { getCustomerAddress } from '../../redux/user/user.actions';
import CheckoutComponent from '../checkout';
import CheckoutBuyComponent from '../checkout/buyNowComponent';
import { GET_CART_SUMMARY } from '../../utils/consts/api';
import LoaderDivComponent from '../../components/loader-component/div-loader';

// eslint-disable-next-line no-shadow
const CartSummaryPage = ({ hidecartBlock, cartItems, getCartItems, token, isBuNowActive, getCustomerAddress }) => {
  const [showCheckoutBox, setCheckoutBox] = useState(false);
  const [priceDetails, setPriceDetails] = useState(0);
  const [cartSummary, setCartSummary] = useState(null);
  const [isCheckoutProccessed, setCheckoutProcess] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  let i = 0;
  useEffect(() => {
    if (i === 0) {
      getCartItems();
      i++;
    }
  }, []);

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      let price = 0;
      setShowLoader(false);
      cartItems.forEach((item) => {
        item.cartProduct.forEach((meti) => {
          price += parseFloat(meti.price);
        });
      });
      setPriceDetails(price.toFixed(2));
    }
  }, [cartItems]);

  useEffect(() => {
    getCustomerAddress();
    setTimeout(() => {
      setShowLoader(false);
    }, 4000);
  }, []);

  const placeFinalOrder = async () => {
    try {
      setCheckoutProcess(true);
      const headers = {
        'Content-Type': 'application/json',
        authorization: token
      };
      const cartData = [];
      cartItems.forEach((itemd) => {
        const obj = {
          merchantId: itemd.merchantId,
          cartProduct: []
        };
        itemd.cartProduct.forEach((prod) => {
          const obj1 = {
            price: prod.price,
            cartId: prod.cartId
          };
          obj.cartProduct.push(obj1);
        });
        cartData.push(obj);
      });
      const postData = {
        cartDetails: cartData
      };
      const res = await axios.post(GET_CART_SUMMARY, postData, { headers });
      if (res.data.responseCode === 0) {
        setCartSummary(res.data.response);
        setCheckoutBox(true);
      } else {
        setCheckoutProcess(false);
        toast.error(`${res.data.errorMsg} Try again`, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      setCheckoutProcess(false);
      toast.error(`${err} Try again`, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const hideCheckoutBox = async () => {
    setCheckoutProcess(false);
    setCheckoutBox(false);
  };

  return (
    <>
      {!isBuNowActive
        && (
        <div style={{ position: 'relative' }}>
          {!showCheckoutBox && (
          <div className="row m-0">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 1px' }}>
              <span>&nbsp;</span>
              <p className="text-center m-0" style={{ fontSize: '18px', fontWeight: '400' }}>Cart Summary</p>
              <AiOutlineCloseCircle style={{ cursor: 'pointer' }} onClick={hidecartBlock} />
            </div>
            {cartItems && cartItems.length > 0 && (
            <>
              <div className="col-12 py-1" style={{ height: '310px', overflowY: 'auto' }}>
                <div className="row">
                  <div className="col-12 pt-1 pb-2" style={{ borderBottom: '1px solid rgb(233 233 233)' }}>
                    <CartComponent />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <h6>Total</h6>
                  <p>{priceDetails}</p>
                </div>
              </div>
              <div className="col-12">
                <button type="button" style={{ marginTop: '5px' }} className={`btn ${styles.customButton}`} disabled={isCheckoutProccessed} onClick={placeFinalOrder}>{isCheckoutProccessed ? 'Processing...' : 'Proceed to Checkout'}</button>
              </div>
            </>
            )}
            {cartItems.length === 0 && !showLoader && (
            <h4 style={{ fontSize: '18px', textAlign: 'center', padding: '40px 10px' }}>Cart is empty. Add items to cart.</h4>
            )}
            {showLoader && (
            <LoaderDivComponent />
            )}
          </div>
          )}
          {showCheckoutBox && cartSummary && <CheckoutComponent backtoCartSummary={hideCheckoutBox} hidecartBlock={hidecartBlock} cartdetails={cartSummary} />}
        </div>
        )}
      {isBuNowActive && (
      <div>
        <CheckoutBuyComponent backtoCartSummary={hideCheckoutBox} hidecartBlock={hidecartBlock} />
      </div>
      )}
    </>
  );
};

CartSummaryPage.propTypes = {
  hidecartBlock: PropTypes.func.isRequired,
  cartItems: PropTypes.array,
  getCartItems: PropTypes.func.isRequired,
  token: PropTypes.string,
  isBuNowActive: PropTypes.bool,
  getCustomerAddress: PropTypes.func
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  cartItems: state.cart.cartItems
});

export default connect(mapStateToProps, { getCartItems, getCustomerAddress })(CartSummaryPage);
