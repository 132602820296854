import React, { useEffect } from 'react';
import styles from './terms.module.scss';

const TandcComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 my-4">
          <h1 className={styles.termsHeading}> BOTIGA TERMS OF USE </h1>
          <div>
            <p>
              Thank you for visiting the Botiga Site and/or using the Botiga App (certain capitalized terms are defined in Section 2 below).
              Please read this Terms of Use Agreement carefully before accessing or using our Platform.
              We have tried to make this Terms of Use as clear as possible for you. This Terms of Use,
              including the Privacy Policy incorporated into these Terms by reference and any other applicable policies and guidelines, as may be updated from time to time, govern your use of the Platform. These Terms constitute a legal agreement between you and Botiga. In order to use the Platform, you must agree to these Terms.
            </p>
            <p>
              BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE PLATFORM, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT AGREE, YOU MAY NOT USE THE PLATFORM.
            </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>1. About Botiga</h3>
            <p>
              Botiga connects consumers to Merchants to watch live and recorded videos and purchase products. Botiga is not, itself, a Merchant. Botiga is not liable or responsible for a Merchant’s compliance with applicable federal, state, or local laws, rules, regulations or standards pertaining to their businesses. In addition, Botiga does not guarantee the quality of what a Merchant sells and does not independently verify, and is not liable for, representations made by Merchant regarding their products on the Platform.
            </p>
            <p>Shipment and Delivery Drivers are independent contractors and not employees, partners, agents, joint ventures, or franchisees of Botiga. Shipment and Delivery Drivers are required to comply with all applicable federal, state, and local laws, rules and regulations. Botiga shall not be liable or responsible for any delivery services provided by Shipment and Delivery Drivers, or any errors or misrepresentations made by any of them. You hereby acknowledge that Botiga does not supervise, direct, control, or monitor a Shipment and Delivery Driver’s provision of services and expressly disclaims any responsibility or liability for the services performed by any Driver.</p>
            <p>Botiga is committed to ensuring that the products ordered by a consumer are delivered in a manner consistent with the consumers’ expectations. As provided in greater detail in these Terms, you agree to and acknowledge these material terms: </p>
            <ul>
              <li>You may use the Platform only as set forth in these Terms; </li>
              <li>Your use of the Platform may be subject to separate third-party terms of service and fees, including without limitation your mobile network operator’s (“Carrier”) terms of service and fees, including fees charged for data usage and overage, which are your sole responsibility; </li>
              <li>You consent to the collection, sharing, and use of your personally identifiable information in accordance with Botiga’s Privacy Policy; </li>
              <li>The Platform is provided “as is” without warranties of any kind, and Botiga’s liability to you is limited; and </li>
              <li>Disputes arising under these Terms will be resolved by binding individual arbitration. </li>

            </ul>
          </div>
          <div>
            <h3 className={styles.subHeading}>2. Definitions</h3>
            <ul>
              <li>The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Use” or the “Terms”) and all other operating rules, policies (including the Botiga Privacy Policy, available at https://www.botiga.live/privacy). </li>
              <li>The “Platform” refers to the Botiga’s website located at www.botiga.live (the “Site”), and all content and products provided by us at or through the Site, and the Botiga mobile or tablet application provided by us and available for download through a link provided on our Site, Google’s Google Play Store or Apple’s App Store (the “App”).  </li>
              <li>“You,” and “your” refer to the individual person, company, or organization that has visited or is using the Platform; that accesses or uses any part of the Platform; or that directs the use of the Platform in the performance of its functions.</li>
              <li>“Botiga,” “we,” and “us” refer to Botiga, Inc., as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.  </li>
              <li>Disputes arising under these Terms will be resolved by binding individual arbitration. </li>
              <li>“Content” refers to content featured or displayed through the Platform, including without limitation text, data, articles, images, photographs, graphics, software, designs, features, and other materials that are available on the Platform. </li>
              <li>“User” refers to you, the consumer, as a visitor of our Site or user of our App. </li>
              <li>“Merchant” includes retailers or merchants whose products are made available for purchase through the App and use the Platform to receive. accept and fulfill orders. </li>
              <li>“Shipment and Delivery Driver” is the party that will be transporting the products from the Merchant to a User, which may include persons engaged by a third-party service provider to fulfill orders, including but not limited to FedEx, UPS, and USPS. </li>
            </ul>
          </div>
          <div>
            <h3 className={styles.subHeading}>3. Registration and Account</h3>
            <p>To access some features of the Platform, you must register for an account. When you register for an account, you may be required to provide us with some information about yourself (such as your name, credit card information, e-mail address, phone number, profile image, or other contact information). You agree that the information you provide to us is accurate, current, and complete, and that you will keep it up-to-date at all times. </p>
            <p>You are the sole authorized User of any account you create through the Platform. You are solely and fully responsible for all activities that occur in connection with your password or account. You agree that you shall monitor your account, and you will accept full responsibility for any unauthorized use of your password or your account by minors. You may not authorize others to use your User status, and you may not assign or otherwise transfer your User account to any other person or entity. Should you suspect that any unauthorized party may be using your password or account, you will notify Botiga immediately. If you provide any information that is untrue, inaccurate, not current, or incomplete, or the Botiga has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete, Botiga has the right to suspend or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof). You agree not to create an account or use the Platform if you have been previously removed by Botiga, or if you have been previously banned from use of the Platform. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>4. Payment Terms</h3>
            <h5>(a) Prices:</h5>
            <p>You understand that: (i) the prices for items displayed through the Platform may differ from the prices offered or published by Merchants for the same items and/or from prices available at other third-party websites and that such prices may not be the lowest prices at which the items are sold; (ii) Botiga has no obligation to itemize its costs, profits or margins when publishing such prices; and (iii) Botiga reserves the right to change such prices at any time, at its discretion. Payment will be processed by Botiga or one of its third-party service providers, using the preferred payment method designated in your account. You are liable for all transaction taxes on the Platform provided under these Terms (other than taxes based on Botiga’s income)</p>
            <h5>(b) Service Charge:</h5>
            <p>In addition to the prices paid for products ordered by a User from Merchants through the Platform and any other applicable taxes and fees, Botiga may charge an additional $1.99 “Service Charge” included as a separate line item on each order, regardless of the size of the order, to offset processing fees, chargebacks, and other miscellaneous expenses. </p>
            <h5>(c) Refunds and Returns: </h5>
            <p>Returns are processed by the Merchant and are subject to their individual policy. Please contact the store to confirm their specific process. The store contact phone number can be found by emailing hello@botiga.live. Refunds are processed as soon as the product is returned and typically hit your account within 3-5 business days after bank processing. Botiga has no obligation to provide refunds or credits that fall outside of the Merchant’s individual policy, but may grant them, in each case in the Company’s sole discretion.  </p>
            <h5>(d) Authorization:</h5>
            <p>You authorize Botiga to charge all sums for orders that you make to the payment method designated in your account. When you place an order through Botiga, a temporary pre-authorization hold is placed on your designated payment method to verify that the card is valid and has the funds available for your intended purchase. The amount of this pre-authorization hold may be greater or less than the order total quoted at checkout. However, your payment will be captured up to twenty-four (24) hours after your order is completed or cancelled. In the event that the pre-authorization is greater than the final amount, the difference will be released after your order is completed or cancelled. Depending on your bank, it may take up to five (5) business days to receive access to these released funds. Botiga also places an initial temporary pre-authorization hold on each new payment method you add to your account. Botiga reserves the right to request additional information from you if we have reason to believe, in our sole discretion, that a payment method may be fraudulent. </p>
            <h5>(c) Cancellations:</h5>
            <p>If you cancel your order, you may still be charged depending on what stage the order was in when you cancelled. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>5. Your Use</h3>
            <p>
              You agree to protect the Platform, and its proprietary content, information and other materials, from any unauthorized access or use, and you agree that you will not use the Platform or such proprietary content, information or other materials except as expressly permitted herein or expressly authorized in writing by Botiga.
            </p>
            <p>You further agree that: </p>
            <ul>
              <li>You will not use the Platform if you are not fully able and legally competent to agree to these Terms; </li>
              <li>You will only use the Platform for lawful purposes and you will not use the Platform for sending or storing any unlawful material or for fraudulent purposes or to engage in any illegal, offensive, indecent or objectionable conduct, explicitly including the purchase of age restricted items if you or the person for whom you are purchasing such items is not of legal age to purchase said items,  or to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party. </li>
              <li>You will not use the Platform to advertise, solicit or transmit commercial advertisements, including “spam”;</li>
              <li>You will not use the Platform to cause nuisance, annoyance or inconvenience;  </li>
              <li>You will not copy, reproduce, adapt, create derivative works of, translate, localize, port or otherwise modify the Platform, any updates, or any part of the Platform or content therein without written permission from Botiga; </li>
              <li>You will only use the Platform for your own use, not for commercial use, and will not distribute, sell, assign, encumber, transfer, rent, lease, loan, sublicense, modify, time-share or otherwise exploit the Platform in any unauthorized manner, including but not limited to by trespass or burdening network capacity, or use the Platform in any service bureau arrangement; </li>
              <li>You will not attempt to obtain any information or content from the Platform using any robot, spider, scraper or other automated means for any purpose; </li>
              <li>You will keep secure and confidential your account password or any identification we provide you which allows access to the Platform; </li>
              <li>You agree not to introduce viruses, time-bombs, worms, cancelbots, trojan horses and/or other harmful code into the Platform; </li>
              <li>You and any recipient of your orders will provide whatever proof of identity and age reasonably requested by the Merchant, its employee or agent, or the Shipment and Delivery Company or Driver used by the Merchant to fulfill your order, or that third-party order fulfillment company’s employee or agent; and </li>
              <li>You will not permit any third party to engage in any of the acts described in any of the clauses contained above in this Section 5. </li>
            </ul>
          </div>
          <div>
            <h3 className={styles.subHeading}>6. Transactions Involving Alcohol or Tobacco</h3>
            <p> You have the option to request delivery of alcohol or tobacco products from Merchants.  If you request or receive your delivery in the United States, you agree that you will only order such products if you are 21 years of age or older, or other legal ager required to purchase age restricted products. You also agree that, upon delivery of age restricted products, you will provide valid government-issued identification proving your age (“ID”) to the Driver delivering the products and will not be intoxicated when receiving delivery of such products. If you order age restricted products, you understand and acknowledge that neither Botiga nor the Driver have the ability to accept your order, and the order can and will only be accepted and delivered if the Merchant accepts the order. The Driver reserves the right to refuse delivery if you are not of legal age to purchase the products, if you cannot provide a valid  ID, if the name on your ID does not match the name on your order, though you may be of legal age you appear to be in the company of minors consuming alcohol, or you are visibly intoxicated. If the Driver is unable to complete the delivery of these products for one or more of these reasons, you may be subject to additional fees. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>7. User Content</h3>
            <h5>(a) User Content:</h5>
            <p>Botiga may provide you with interactive opportunities through the Platform, including, by way of example, the ability to chat with Merchants, and post User ratings and reviews (collectively, “User Content”). You represent and warrant that you are the owner of, or otherwise have the right to provide, all User Content that you submit, post and/or otherwise transmit through the Platform. You hereby grant Botiga a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use the User Content in connection with Botiga’s business and in all forms now known or hereafter invented (“Uses”), without notification to and/or approval by you. You further grant Botiga a license to use your username and/or other User profile information, including without limitation your ratings history, to attribute User Content to you in connection with such Uses, without notification or approval by you. You agree not to use the Platform in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that Botiga is not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive or illegal messages or transmissions that you may receive as a result of using the Platform. </p>
            <h5>(b) Feedback:</h5>
            <p>You agree that any submission of any ideas, suggestions, and/or proposals to Botiga through its suggestion, feedback, wiki, forum or similar pages (“Feedback”) is at your own risk and that Botiga has no obligations (including without limitation, obligations of confidentiality) with respect to such Feedback. You represent and warrant that you have all rights necessary to submit the Feedback and you hereby grant to Botiga a perpetual, irrevocable, transferable, fully paid, royalty-free, non-exclusive, worldwide, fully sublicensable right and license to use, copy, display, publish, modify, remove, publicly perform, translate, create derivative works, distribute and/or otherwise use such Feedback.</p>
            <h5>(c) Ratings and Reviews: </h5>
            <p>To the extent that you are asked to rate and post reviews of Merchant or other businesses (“Ratings” and “Reviews”), such Ratings and Reviews are considered User Content and are governed by this Agreement. Ratings and Reviews are not endorsed by Botiga and do not represent the views of Botiga or its affiliates. Botiga does not assume liability for Ratings and Reviews or for any claims for economic loss resulting from such Ratings and Reviews. Because we strive to maintain a high level of integrity with respect to Ratings and Reviews posted or otherwise made available through the Platform, you agree that: (i) you will base any Rating or Review on first-hand experience with the Merchant or business; (ii) you will not provide a Rating or Review for any Merchant or business for which you have an ownership interest, employment relationship or other affiliation or for any of that company’s competitors; (iii) you will not submit a Rating or Review in exchange for payment, free food items, or other benefits from a Merchant or business and (iv) your review will comply with the terms of this Agreement. If we determine, in our sole discretion, that any Rating or Review could diminish the integrity of the Ratings and Reviews, we may exclude such User Content without notice.</p>
          </div>
          <div>
            <h3 className={styles.subHeading}>8.Intellectual Property Notice </h3>
            <p>Botiga hereby grants to you, subject to these Terms, a personal, nonexclusive, nontransferable, limited license (without the right to sublicense) to access and use the Platform (including updates and upgrades that replace or supplement it in any respect and which are not distributed with a separate license, and any documentation) solely for your personal use on a mobile or other device that you own or control. These Terms do not permit you to visit the Site or install or use the App on a mobile or other device that you do not own or control and you may not distribute or make all or any portion of the Platform available over a network where it could be used by multiple devices at the same time. </p>
            <p>Botiga, our licensors, vendors, agents, and/or our Content providers retain ownership of all intellectual property rights of any kind related to the Platform. You should assume that everything you see or read on the Platform is proprietary information protected by copyright or trademark unless otherwise noted and may not be used except with the written permission of Botiga. We reserve all rights that are not expressly granted to you under this Agreement or by law. </p>
            <p>The looks and feel of the Site and App are our copyright. You may not duplicate, copy, or reuse any portion of the HTML/CSS, Javascript, or visual design elements or concepts without express written permission from Botiga. </p>
            <p>All trademarks, service marks, trade names, logos and trade dress, whether registered or unregistered (collectively the “Marks”) that appear on the Platform are proprietary to Botiga or such Marks’ respective owners. You may not display or reproduce the Marks other than with our prior written consent, and you may not remove or otherwise modify any trademark notices from any Content. The Marks are protected by trademark, trade dress, copyright, and various other intellectual property rights and unfair competition laws. </p>
            <p>All rights not expressly granted herein are reserved by Botiga. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>9.Communications with Botiga</h3>
            <p>We use email, instant messaging and other electronic means to stay in touch with our users. Additionally, we may contact you via telephone or the postal service. For contractual purposes, you: </p>
            <ul>
              <li>Consent to receive communications from us in an electronic form via the email address you have submitted, instant messaging through the Platform, text messaging, or via the Site or App; </li>
              <li>Consent to be contacted by phone or physical mail; and </li>
              <li>Agree that all of the Terms of Use, agreements, notices, policies, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. </li>
            </ul>
            <p>This section does not affect your non-waivable rights. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>10.SMS/MMS Mobile Message Marketing Program Terms</h3>
            <p>Botiga offers a mobile messaging program which may be provided through a third party (the “Program”), which you agree to use and participate in, subject to these Mobile Messaging Terms and Conditions (the “Messaging Terms”). By opting in to or participating in any of our Programs, you accept and agree to these terms and conditions, including, without limitation, your agreement to resolve any disputes with us through binding, individual-only arbitration, as detailed in the “Dispute Resolution and Arbitration” section below. These Messaging Terms are limited to the Program and are not intended to modify the other Terms of Use or Privacy Policy that may govern the relationship between you and Botiga in other contexts. </p>
            <h5>(a) User Opt In: </h5>
            <p>The Program allows Users to receive SMS/MMS mobile messages by affirmatively opting into the Program, such as through online or application-based enrollment forms. Regardless of the opt-in method you utilized to join the Program, you agree that these Messaging Terms apply to your participation in the Program. By participating in the Program, you agree to receive autodialed or prerecorded marketing mobile messages at the phone number associated with your opt-in, and you understand that such consent is not required in order to make any purchase from Botiga. While you consent to receive messages sent using an autodialer, the foregoing shall not be interpreted to suggest or imply that any or all of our mobile messages sent on behalf of Botiga are sent using an automatic telephone dialing system (“ATDS” or “autodialer”). Message and data rates may apply.</p>
            <h5>(b) User Opt Out:</h5>
            <p>If you do not wish to continue participating in the Program or no longer agree to these Messaging Terms, you agree to reply STOP, END, CANCEL, UNSUBSCRIBE, or QUIT to any mobile message from Botiga in order to opt out of the Program. You may receive an additional mobile message confirming your decision to opt out. You understand and agree that the foregoing options are the only reasonable methods of opting out. You also understand and agree that any other method of opting out, including, but not limited to, texting words other than those set forth above or verbally requesting one of our employees to remove you from our list, is not a reasonable means of opting out. </p>
            <h5>(c) Duty to Notify and Indemnify:</h5>
            <p>If at any time you intend to stop using the mobile telephone number that has been used to subscribe to the Program, including canceling your service plan or selling or transferring the phone number to another party, you agree that you will complete the User Opt Out process set forth above prior to ending your use of the mobile telephone number. You understand and agree that your agreement to do so is a material part of these terms and conditions. You further agree that, if you discontinue the use of your mobile telephone number without notifying Botiga of such change, you agree that you will be responsible for all costs (including attorneys’ fees) and liabilities incurred by Botiga, or any party that assists in the delivery of the mobile messages, as a result of claims brought by individual(s) who are later assigned that mobile telephone number. This duty and agreement shall survive any cancellation or termination of your agreement to participate in any of our Programs. YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND, AND HOLD US HARMLESS FROM ANY CLAIM OR LIABILITY RESULTING FROM YOUR FAILURE TO NOTIFY US OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED, INCLUDING ANY CLAIM OR LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. § 227, et seq., OR SIMILAR STATE AND FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER RESULTING FROM US ATTEMPTING TO CONTACT YOU AT THE MOBILE TELEPHONE NUMBER YOU PROVIDED. </p>
            <h5>(d) Program Description:</h5>
            <p>Without limiting the scope of the Program, Users that opt into the Program can expect to receive messages concerning the marketing and sale of digital and physical products, services, and events. </p>
            <h5>(e) Cost and Frequency:</h5>
            <p>Message and data rates may apply. The Program involves recurring mobile messages, and additional mobile messages may be sent periodically based on your interaction with Botiga. </p>
            <h5>(f) MMS Disclosure:</h5>
            <p>The Program will send SMS TMs (terminating messages) if your mobile device does not support MMS messaging. </p>
            <h5>(g) Our Disclaimer of Warranty:</h5>
            <p>The Program is offered on an “as-is” basis and may not be available in all areas at all times and may not continue to work in the event of product, software, coverage or other changes made by your Carrier. We will not be liable for any delays or failures in the receipt of any mobile messages connected with this Program. Delivery of mobile messages is subject to effective transmission from your wireless service provider/network operator and is outside of Botiga’s control. </p>
            <h5>(h) Participant Requirements:</h5>
            <p>When using the mobile application, you must have a wireless device of your own, capable of two-way messaging, be using a participating Carrier, and be a wireless service subscriber with text messaging service. Not all cellular phone providers carry the necessary service to participate. Check your phone capabilities for specific text messaging instructions. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>11.Termination and Suspension</h3>
            <p>Botiga has the right to suspend or terminate your account or access to all or any part of the Platform at any time, with or without cause, with or without notice, effective immediately. Botiga reserves the right to refuse Platform to anyone for any reason at any time. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>12.Dispute Resolution and Arbitration</h3>
            <h5>(a) This is a Class Action Waiver: </h5>
            <p>In the interest of resolving disputes between you and Botiga in the most expedient and cost effective manner, you and Botiga agree that every dispute arising in connection with these Terms will be resolved by binding individual arbitration. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, and can be subject to very limited review by courts. While the parties will be permitted to engage in discovery or exchange of non-privileged information relevant to the dispute, arbitration may allow for more limited discovery allowed for in court. Arbitrators can award the same damages and relief that a court can award. Our agreement to arbitrate disputes includes all claims arising out of or relating to any aspect of these Terms, the Messaging Terms, the Platform, or your relationship with us, whether based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms. </p>
            <p>YOU AND BOTIGA AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. </p>
            <p>Unless both you and Botiga agree, no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any form of a representative or class proceeding. The court may award injunctive relief only in favor of the individual party seeking relief and only to the extent necessary to provide relief warranted by that party’s individual claim.  </p>
            <p>Any arbitration between you and Botiga shall be conducted by a single arbitrator, governed by JAMS pursuant to its Comprehensive Arbitration Rules & Procedures (collectively, “JAMS Rules”), as modified by these Terms, and administered by JAMS. The JAMS Rules and fee information are available at www.jamsadr.org or by calling JAMS at 1-800-352-5267. The arbitrator is bound by these arbitration terms. All issues are for the arbitrator to decide, including, but not limited to, issues relating to the scope, enforceability, and arbitrability of this Section 12. </p>
            <h5>(b) Notice:</h5>
            <p>A party who intends to seek arbitration must first send a written notice of the dispute to the other, by certified mail or Federal Express (signature required), or if we do not have a physical address on file for you, by electronic mail (“Notice”).</p>
            <p>Botiga’s address for Notice is: </p>
            <p>Botiga Live, Inc. </p>
            <p> Attn: Legal</p>
            <p> 3426 Lomas Serenas Dr,</p>
            <p> Escondido, CA 92029 </p>
            <p>The Notice must: (i) Describe the nature and basis of the claim or dispute; and (ii) Set forth the specific relief sought.  We agree to use good faith efforts to resolve the claim directly, but if we do not reach an agreement to do so within thirty (30) days after the Notice is received, you or Botiga may commence an arbitration proceeding. </p>
            <h5>(c) Opt Out of Arbitration:</h5>
            <p>You have the right to opt out and not be bound by the arbitration and class action waiver provisions set forth above by submitting a request to the address for Notice listed above. The notice must be sent within thirty (30) days of your creating an account with Botiga, otherwise, you shall be bound to arbitrate disputes in accordance with the terms of these paragraphs.  If you opt out of these arbitration provisions, Botiga also will not be bound by them. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>13.Disclaimer of Warranties</h3>
            <p>The Platform is provided “as is” and “as available,” without warranty of any kind. We do not warrant that the Platform will meet your requirements; that the Platform will be uninterrupted, timely, secure, or error-free; that the information provided through the Platform is accurate, reliable or correct; that any defects or errors will be corrected; that the Platform will be available at any particular time or location; or that the Platform is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Platform. </p>
            <p>We make no promises or guarantees about the Platform. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Platform including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement. </p>
            <p>Botiga relies upon Merchants to provide accurate information. We do not represent or warrant that such information is accurate, complete, reliable, current, or error free. We do not warrant, endorse, guarantee, or assume responsibility for any product advertised or offered by a Merchant or other third-party through the Platform, and Botiga is not and will not be a party to or in any way monitor any transaction between you and third-party providers of products. </p>

          </div>
          <div>
            <h3 className={styles.subHeading}>14. Limitation of Liability </h3>
            <p>IN NO EVENT SHALL BOTIGA’S AGGREGATE LIABILITY EXCEED THE BOTIGA FEES ACTUALLY PAID BY YOU TO BOTIGA IN THE THREE (3) MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR $100, WHICHEVER IS LESS. IN NO EVENT SHALL BOTIGA AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY INDIRECT, PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY, LOSS OF DATA, REVENUE, PROFITS, REPUTATION, USE OR OTHER ECONOMIC ADVANTAGE) EVEN IF BOTIGA AND/OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. </p>
            <p>We will not be liable for damages or losses arising from your use or inability to use the Platform or otherwise arising under this Agreement. You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from: </p>
            <ul>
              <li>Your use or inability to use the Platform; </li>
              <li>Unauthorized access to or alterations of your transmissions or data; </li>
              <li>Any other User interactions that you input or receive through your use of the Platform; or </li>
              <li>Any other matter relating to the Platform. </li>
            </ul>
            <p>Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control. </p>

          </div>
          <div>
            <h3 className={styles.subHeading}>15. Indemnity </h3>
            <p>You agree to indemnify, defend, and hold harmless Botiga, its affiliates, officers, directors, employees, consultants, agents, and representatives from any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from your access to or use of the Platform, your violation of these Terms of Use, or your infringement, of any intellectual property or other right of any person or entity. Botiga will notify you promptly of any such claim, loss, liability, or demand, and will provide you with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost. This explicitly includes indemnification by you for any claim or charge related to the purchase, sale, or delivery of an alcohol or tobacco product through your account to a person, including yourself, not of legal age required to purchase such product. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>16. Third-Party Interactions </h3>
            <h5>(a) Third-Party Websites & Advertisements:</h5>
            <p>We may link to or be linked from other websites and the Platform may contain third-party advertisements (collectively “Third-Party Websites & Advertisements”) that are not maintained by, or related to, Botiga. We do not endorse, and are not responsible for, the content of any of those Third-Party Websites & Advertisements. You acknowledge Botiga has not reviewed and does not endorse the content of all sites linked to or from the Platform and is not responsible for the content or actions of any other sites linked to or from, or advertisements contained on the Platform. We do not promise that the contents of any Third-Party Websites & Advertisements are accurate and/or compliant with local, state or federal law, including any intellectual property laws. Your visiting of any linked site is at your own risk and you assume all responsibilities and consequences resulting from such visitation. </p>
            <h5>(b) Social Networks:  </h5>
            <p>The Platform includes features that operate in conjunction with certain third-party social networking websites, such as Facebook, Instagram, Twitter, etc. (“Social Network Features”). While your use of the Social Network Features is governed by this Agreement, your access and use of third-party social networking websites and the services provided through those websites is governed by the terms of service and other agreements posted on those websites. You understand and acknowledge that the Platform is not endorsed or certified by any of the aforementioned social networking websites. In addition, you agree that any content posted through the Social Network Features complies with the respective social network’s terms of service or other applicable agreements. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>17. Changes to These Terms </h3>
            <p>We reserve the right, at our sole discretion, to amend these Terms of Use at any time and will update these Terms of Use in the event of any such amendments. We will notify our Users of material changes to this Agreement and want you to be informed of important changes to these Terms, but some changes are not that important and we don’t want to bother you every time we fix a typo. So, while we may modify this agreement at any time, we will notify Users of any changes that affect your rights. We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Site, App, or any part of the Platform with or without notice. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>18. What if I live outside of the United States </h3>
            <p>Botiga is hosted and operated entirely in the United States and is subject to United States law. Please do not provide us with any information if you live outside of the United States. We do not operate outside of the 48-contiguous states. If you are from a jurisdiction outside of the United States, please notify us so that we may take necessary action. This may include terminating your access to the Platform, your account and deleting your information. We are committed to resolving those issues, so if you have any questions about how we collect or use your information you may email us at hello@botiga.live.  </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>19. Miscellaneous </h3>
            <h5>(a) Governing Law:  </h5>
            <p>Except to the extent applicable law provides otherwise, this Agreement between you and Botiga and any access to or use of the Platform are governed by the federal laws of the United States of America and the laws of the State of California, without regard to conflict of law provisions. You and Botiga agree to submit to the exclusive jurisdiction and venue of the state and federal courts located within the City and County of San Diego, California. </p>
            <h5>(b) Section Headings and Titles:</h5>
            <p>Throughout this Agreement, each may section includes titles and/or headings regarding the terms and conditions that follow. These section titles and headings are for convenience only and are not to be used in the interpretation of this Agreement. </p>
            <h5>(c) Severability, No Waiver, and Survival:  </h5>
            <p>If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of Botiga to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability. </p>
          </div>
          <div>
            <h5>20. Contact Us </h5>
            <p>If you have any questions or comments relating to the Botiga Site, App or these Terms of Use, you can submit a question on our Site or send an email to hello@botiga.live.  </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TandcComponent;
