import io from 'socket.io-client';

const SOCKET_SERVER = 'http://3.88.169.230'; // 'https://api.botiga.live';
// const SOCKET_SERVER =  'http://18.220.193.215:3000/';
// const SOCKET_SERVER = 'http://localhost:5000/';

let socketConnection = null;

export const END_LIVE_STREAM = 'endLiveStream';
export const NEW_USER = 'new-user';
export const SEND_CHAT_MESSAGE = 'send-chat-message';
export const HEART_REACTION = 'heartReaction';
export const BOOK_MARK_PRODUCT = 'bookmarkProduct';

export const connectSocket = () => {
  socketConnection = io.connect(SOCKET_SERVER);
  return socketConnection.connected;
};
// ${data.webinarId}_pubsub
// testingForPortal
export const listenSocketEvent = (data) => {
  // socketConnection.on('connection', (socket) => {

  // });
  console.log(socketConnection, 'connect');
  socketConnection.on(`${data}_pubsub`, (response) => {
    console.log(response);
    return response;
  });
};

export const emitSocketEvent = (eventType, data) => {
  console.log(eventType);
  socketConnection.on('connection', (socket) => {
    socket.emit(eventType, data);
  });
};

export const disconnectCurrentSocket = () => {
  // console.log('disconnect current socket');
  if (socketConnection) {
    socketConnection.disconnect();
  }
};

export const emitSocketLikeEvent = (data) => {
  console.log('inside');
  console.log(socketConnection);
  console.log(data, 'data');
  socketConnection.on('connection', (socket) => {
    socket.emit(HEART_REACTION, data);
  });
};
