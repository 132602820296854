/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { AiOutlineClose, AiFillGoogleCircle } from 'react-icons/ai';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import styles from './dialogBox.module.scss';
import facebook from '../../assets/images/facebook.png';
import LoginComponent from '../login-component';
import RedgComponent from '../redg-component';
import { googleSignInStart } from '../../redux/user/user.actions';

const LoginRegisterDialog = ({ open, onDisagree, userId }) => {
  const dispatch = useDispatch();
  const [openToRedg, setOpenToRedg] = useState(false);
  useEffect(() => {
    setOpenToRedg(false);
  }, '');
  const handleOnClickToRedg = () => {
    setOpenToRedg(true);
  };
  const handleOnClickToLogin = () => {
    setOpenToRedg(false);
  };

  const googleResponse = (e) => {
    if (e && e.tokenId) {
      const obj = {
        loginType: 'social',
        facebookId: '',
        name: e.profileObj.name,
        userName: e.profileObj.email.split('@')[0],
        emailId: e.profileObj.email,
        email: e.profileObj.email,
        googleId: e.profileObj.googleId,
        token: e.tokenId
      };
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const facebookResponse = (e) => {
    if (e && e.id) {
      const obj = {
        loginType: 'social',
        facebookId: e.id,
        googleId: '',
        name: e.name,
        email: e.email,
        userName: e.email.split('@')[0],
        emailId: e.email,
        token: e.accessToken
      };
      // console.log('google 2', obj);
      dispatch(googleSignInStart(obj));
    } else {
      toast.warning('Login Request unsuccessful.', {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onFailure = (error) => {
    console.log(error);
  };

  return (
    <div>
      <Dialog
        open={open && !userId}
        keepMounted
        onClose={onDisagree}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onBackdropClick="false"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: '0px 5px 0', textAlign: 'center' }}>
          <h6 className={styles.headText} style={{ display: 'inline-block' }}>{openToRedg ? 'Sign UP' : 'Sign In'}</h6>
          <AiOutlineClose style={{ cursor: 'pointer', float: 'right' }} onClick={onDisagree} />
        </DialogTitle>
        <DialogContent style={{ width: '350px' }}>
          <DialogContentText id="alert-dialog-description">
            {!openToRedg && (
            <div style={{ width: '100%' }}>
              <LoginComponent />
              <div className={styles.redgLink} onClick={() => handleOnClickToRedg()}>CREATE ACCOUNT?</div>
              <div className="row m-0 mt-5">
                <div className="col-12 text-center">
                  <h6 className="mb-3" style={{ fontSize: '12px', color: '#424242' }}>OR SIGN IN WITH</h6>
                  <div className="w-100" style={{ display: 'flex', justifyContent: 'center' }}>
                    <GoogleLogin
                      clientId="785999517189-c21b8ddublm78ai7e1p773et84r7re87.apps.googleusercontent.com"
                      buttonText=""
                      onSuccess={googleResponse}
                      onFailure={onFailure}
                      className={styles.customizeGoogle}
                    >
                      <AiFillGoogleCircle />
                    </GoogleLogin>
                    <FacebookLogin
                      appId="1353555445149084"
                      autoLoad={false}
                      fields="name, email, picture"
                      scope="public_profile, email"
                      callback={facebookResponse}
                      render={(renderProps) => (
                        <button type="button" onClick={renderProps.onClick} className={styles.customizeFacebook}>
                          <img src={facebook} alt="" width="45px" />
                        </button>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            )}
            {openToRedg && (
            <div style={{ width: '100%' }}>
              <RedgComponent />
              <div className={styles.redgLink} style={{ textAlign: 'center' }} onClick={() => handleOnClickToLogin()}>ALREADY HAVE AN ACCOUNT? SIGN IN</div>
            </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
};

LoginRegisterDialog.propTypes = {
  userId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  userId: state.user.currentUser
});

export default connect(mapStateToProps, null)(LoginRegisterDialog);
