import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import FooterComponent from '../footer';
import HeaderComponent from '../header';

const MainLayoutComponent = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const checkIfMobile = () => {
    const becameMobile = window.innerWidth <= 768;

    if (isMobile !== becameMobile) {
      setIsMobile(becameMobile);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
  }, [isMobile]);

  return (
    <div className="container-fluid">
      {!isMobile && (
      <div className="row">
        <div className="col-12">
          <HeaderComponent />
        </div>
      </div>
      )}
      <div className="row">
        <div className="col-12 p-0">
          <Outlet />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <FooterComponent />
        </div>
      </div>
    </div>
  );
};

export default MainLayoutComponent;
