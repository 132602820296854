import React, { useState, useEffect } from 'react';
import { AiOutlineRight, AiOutlineArrowLeft, AiOutlineDelete } from 'react-icons/ai';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import Radio from '@mui/material/Radio';
import styles from './checkout.module.scss';
import { STRIPE_SECRET_KEY, API_STRIPE_PAYMENT, GET_CART_SUMMARY } from '../../utils/consts/api';
import CartComponent from '../../components/Cart/cart';
import StripePaymentComponent from '../../components/stripe-payment';
import AddressComponent1 from './AddressComponent';
// import { getCustomerAddress } from '../../redux/user/user.actions';
import { clearCart } from '../../redux/cart/cart.actions';

// eslint-disable-next-line no-shadow
const BuyNowCheckoutComponent = ({ hidecartBlock, cartItems, userId, token, allAddress, clearCart, backtoCartSummary, stripeCustomerId }) => {
  // const handleImageErr = (e) => {
  //   e.target.src = dummy;
  // };
  const [cartdetails, setCartDetails] = useState(null);
  const [showAddressDiv, setAddressDiv] = useState(false);
  const [showPaymentDiv, setPaymentDiv] = useState(false);
  const [isOrderPlaced, setOrderPlaced] = useState(false);
  const [isSaveCardChecked, checkSaveCardOption] = useState(false);
  const [showPriceBreak, setPriceBreak] = useState(false);
  // const [showLoader, setShowLoader] = useState(true);
  const onClickhandlePaymentPage = () => {
    setPaymentDiv(!showPaymentDiv);
  };
  const [shhipingAddress, setshhipingAddress] = useState(null);
  const [cardLists, setCardLists] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [isNewCardFormOpen, setNewCardFromOpen] = useState(false);
  const [addressList, setAddressList] = useState(allAddress);

  // const [priceDetails, setPriceDetails] = useState(0);

  const getFinalCartPrice = async (dtarr) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        authorization: token
      };
      const cartData = [];
      dtarr.forEach((itemd) => {
        const obj = {
          merchantId: itemd.merchantId,
          cartProduct: []
        };
        itemd.cartProduct.forEach((prod) => {
          const obj1 = {
            price: prod.price,
            cartId: prod.cartId
          };
          obj.cartProduct.push(obj1);
        });
        cartData.push(obj);
      });
      const postData = {
        cartDetails: cartData
      };
      const res = await axios.post(GET_CART_SUMMARY, postData, { headers });
      if (res.data.responseCode === 0) {
        console.log('inside');
        setCartDetails(res.data.response);
      } else {
        toast.error(`${res.data.errorMsg} Try again`, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {
      toast.error(`${err} Try again`, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    // if (buyNowProductObject && allCartItems && allCartItems.length > 0) {
    //   const dt = allCartItems.map((element) => {
    //     return { ...element, cartProduct: element.cartProduct.filter((subElement) => subElement.productId === buyNowProductObject.productId) };
    //   });
    //   setCartItems(dt);
    getFinalCartPrice(cartItems);
  }, [cartItems]);

  const getCardListStripe = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${STRIPE_SECRET_KEY}`
      };
      await axios.get(`https://api.stripe.com/v1/customers/${stripeCustomerId}/sources?object=card`, { headers })
        .then((res) => {
          if (res) {
            if (res.data.data.length > 0) {
              res.data.data.forEach((pl) => {
                pl.isDefault = false;
              });
              res.data.data[0].isDefault = true;
              setCardLists(res.data.data);
              setSelectedCard(res.data.data[0]);
              checkSaveCardOption(true);
            }
          }
        }, (error) => {
          toast.error(error, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } catch (err) {
      toast.error(err, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  let i = 0;
  useEffect(() => {
    if (i === 0 && stripeCustomerId) {
      getCardListStripe();
      i++;
    }
  }, []);

  useEffect(() => {
    if (cardLists.length > 0) {
      const d = cardLists.find((pl) => pl.isDefault);
      if (d) {
        setSelectedCard(d);
      }
    }
  }, [cardLists]);

  useEffect(() => {
    setAddressList(allAddress);
  }, [allAddress]);

  useEffect(() => {
    if (addressList.length > 0) {
      const adr = addressList.find((adritem) => adritem.selectedAddressId === adritem.addressId);
      if (adr) {
        setshhipingAddress(adr);
      } else {
        setshhipingAddress(addressList[0]);
      }
    } else {
      setshhipingAddress(null);
    }
  }, [addressList]);

  const addNewCard = () => {
    setNewCardFromOpen(true);
  };

  const setActiveCard = (item, isCardSave) => {
    if (isCardSave) {
      const d = [...cardLists];
      d.forEach((itm) => {
        itm.isDefault = false;
      });
      item.isDefault = true;
      d.push(item);
      setCardLists(d);
    } else {
      item.card.tokenId = item.id;
      setSelectedCard(item.card);
    }
    setPaymentDiv(false);
    setNewCardFromOpen(false);
    checkSaveCardOption(isCardSave);
  };

  const placeFinalOrderAction = async () => {
    try {
      setOrderPlaced(true);
      const headers = {
        'Content-Type': 'application/json',
        authorization: token
      };
      const cartData = [];
      cartItems.forEach((itemd) => {
        const obj = {
          merchantId: itemd.merchantId,
          cartProduct: []
        };
        itemd.cartProduct.forEach((prod) => {
          const obj1 = {
            price: prod.price,
            cartId: prod.cartId
          };
          obj.cartProduct.push(obj1);
        });
        cartData.push(obj);
      });
      // let obj = {
      //   cartTotalWithoutTax: priceDetails.price,
      //   stripeCardId: selectedCard.card.id,
      //   shippingCharge: priceDetails.shipCharges,
      //   cartDetails: cartdetails,
      //   stripeCustomerId: '',
      //   totalSummary: priceDetails.price,
      //   estimatedTax: priceDetails.tax,
      // };
      const obj = {
        productPriceChange: cartdetails.productPriceChange,
        cartTotalWithoutTax: cartdetails.cartTotalWithoutTax,
        shippingCharge: cartdetails.shippingCharge,
        estimatedTax: cartdetails.estimatedTax,
        totalSummary: cartdetails.totalSummary,
        cartDetails: cartdetails.cartSummaryDetails
      };

      if (isSaveCardChecked) {
        obj.stripeCustomerId = stripeCustomerId || selectedCard.customer;
        obj.stripeCardId = selectedCard.id;
      } else {
        obj.stripeToken = selectedCard.tokenId;
        obj.expiryDate = selectedCard.exp_year;
      }

      console.log(obj);

      const postData = { ...obj, ...shhipingAddress };
      const firstPaymentAPI = await axios.post(API_STRIPE_PAYMENT, postData, { headers });
      console.log(firstPaymentAPI);
      const response = firstPaymentAPI;
      if (response.data.responseCode === 0) {
        // postData.orderId = response.data.response.orderId;
        clearCart();
        hidecartBlock();
        setCartDetails(null);
        // window.sessionStorage.setItem('currentOrder', JSON.stringify(obj));
        toast.success('Order placed successfully!', {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        setOrderPlaced(false);
        toast.warning(response.data.errorMsg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      setOrderPlaced(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateInExistingAddress = (item) => {
    setAddressList(item);
    setAddressDiv(!showAddressDiv);
  };

  const onClickhandleAddress = () => {
    setAddressDiv(!showAddressDiv);
    // setshowAddressForm(false);
    // seteditAddress(false);
    // setkeepEditAddressData('');
  };

  const handleCardSelection = (item) => {
    const d = [...cardLists];
    d.forEach((pt) => {
      if (pt.id === item.id) {
        pt.isDefault = true;
      } else {
        pt.isDefault = false;
      }
    });
    setCardLists(d);
    checkSaveCardOption(true);
    onClickhandlePaymentPage();
  };

  const deleteCard = async (item) => {
    try {
      const headers = {
        Authorization: `Bearer ${STRIPE_SECRET_KEY}`
      };
      await axios.delete(`https://api.stripe.com/v1/customers/${stripeCustomerId}/sources/${item.id}`, { headers })
        .then((res) => {
          if (res) {
            toast.success('Card removed successfully!', {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // const d = [...cardLists];
            const l = cardLists.filter((dd) => dd.id !== item.id);
            setCardLists(l);
            if (item.id === selectedCard.id) {
              setSelectedCard(null);
            }
          }
        }, (error) => {
          toast.error(error, {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } catch (err) {
      toast.error(err, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const showPriceBreakup = () => {
    setPriceBreak(!showPriceBreak);
  };
  return (
    <div style={{ position: 'relative' }}>
      {(!showAddressDiv && !showPaymentDiv) && (
        <div className="row m-0">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '8px 0px' }}>
            <AiOutlineArrowLeft style={{ cursor: 'pointer' }} onClick={backtoCartSummary} />
            <p className="text-center m-0" style={{ fontSize: '18px', fontWeight: '400' }}>Summary</p>
            {/* <AiOutlineCloseCircle style={{ cursor: 'pointer' }} onClick={hidecartBlock} /> */}
            <span />
          </div>
          <div className="col-12 py-1" style={{ height: '69vh', overflowY: 'auto' }}>
            <div className="row">
              <div className="col-12 pt-1 pb-2" style={{ borderBottom: '1px solid rgb(233 233 233)' }}>
                <CartComponent />
              </div>
              {cartdetails && cartdetails.cartSummaryDetails.length !== 0 && (
              <div className={`col-12 py-3 ${styles.mainPrice}`}>
                <div className={styles.totalPrice}>
                  <h4 style={{ fontSize: '14px', fontWeight: '600', marginBottom: '0px' }}>Total Price</h4>
                  <h4 style={{ fontSize: '14px', fontWeight: '600', marginBottom: '0px' }}>{`US $${cartdetails.totalSummary.toFixed(2)}`}</h4>
                </div>
                <div className={styles.priceHead}>
                  {!showPriceBreak && <span onClick={showPriceBreakup}>Price Breakdown</span>}
                  {showPriceBreak && <span onClick={showPriceBreakup}>Hide</span>}
                </div>
                {showPriceBreak && (
                <div>
                  <div className={styles.priceDetailLists}>
                    <p>Product Price</p>
                    <p>{`$${cartdetails.cartTotalWithoutTax.toFixed(2)}`}</p>
                  </div>
                  <div className={styles.priceDetailLists}>
                    <p>Shipping Charge</p>
                    <p>{`$${cartdetails.shippingCharge.toFixed(2)}`}</p>
                  </div>
                  <div className={styles.priceDetailLists}>
                    <p>Tax</p>
                    <p>{`$${cartdetails.estimatedTax.toFixed(2)}`}</p>
                  </div>
                  {/* <div className={styles.priceDetailLists}>
                    <p>Total</p>
                    <p>{`$${cartdetails.totalSummary.toFixed(2)}`}</p>
                  </div> */}
                </div>
                )}
              </div>
              )}
              {cartItems && cartItems.length > 0 && (
              <>
                {shhipingAddress && (
                  <div className="col-12 py-3" style={{ borderBottom: '1px solid rgb(233 233 233)', cursor: 'pointer' }} onClick={onClickhandleAddress}>
                    <p style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>Shipping Address</p>
                    <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                      <p style={{ fontSize: '13px', color: '#727272', opacity: '1', margin: '0', width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <span style={{ color: '#424242', fontWeight: '900', marginRight: '10px' }}>{shhipingAddress.shippingAddressName ? `${shhipingAddress.shippingAddressName}` : ''}</span>
                        {shhipingAddress.addressLine1 ? `${shhipingAddress.addressLine1}, ` : ''}
                        {shhipingAddress.addressLine2 ? `${shhipingAddress.addressLine2}, ` : ''}
                        {shhipingAddress.city ? `${shhipingAddress.city}, ` : ''}
                        {shhipingAddress.pinCode ? `${shhipingAddress.pinCode}, ` : ''}
                        {shhipingAddress.state ? `${shhipingAddress.state}, ` : ''}
                        {shhipingAddress.phoneNumber ? `${shhipingAddress.phoneNumber}` : ''}
                      </p>
                      <AiOutlineRight />
                    </div>
                  </div>
                )}
                {!shhipingAddress && (
                  <div className="col-12 pt-2 pb-2" style={{ borderBottom: '1px solid rgb(233 233 233)', cursor: 'pointer' }} onClick={onClickhandleAddress}>
                    <p style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>Shipping Address</p>
                    <p>Shipping Address not found. Click here to add.</p>
                  </div>
                )}
                <div className="col-12 py-3" style={{ borderBottom: '1px solid rgb(233 233 233)', cursor: 'pointer' }} onClick={onClickhandlePaymentPage}>
                  {selectedCard && (
                  <>
                    <p style={{ fontSize: '14px', fontWeight: '600', margin: '0' }}>Pay with</p>
                    <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                      <div>
                        <h4 style={{ fontSize: '16px', marginBottom: '0px' }}>{selectedCard.brand}</h4>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '225px' }}>
                          <span>{`xxxx xxxx xxxx ${selectedCard.last4}`}</span>
                          <span>
                            {selectedCard.exp_month.length === 2 ? selectedCard.exp_month : `0${selectedCard.exp_month}`}
                            /
                            {selectedCard.exp_year}
                          </span>
                        </div>
                      </div>
                      <AiOutlineRight />
                    </div>
                  </>
                  )}
                  {!selectedCard && (
                    <div className={styles.cardNotFound}>
                      <h4>Card details has not been added.</h4>
                      <p>Click here to add</p>
                    </div>
                  )}
                </div>
              </>
              )}
            </div>
          </div>
          {cartItems && cartItems.length > 0 && selectedCard && shhipingAddress && (
          <div className="col-12 pb-1">
            <button type="button" disabled={isOrderPlaced || cartItems.length === 0} className={`btn ${styles.customButton}`} onClick={placeFinalOrderAction}>{isOrderPlaced ? 'Processing...' : 'PLACE YOUR ORDER'}</button>
          </div>
          )}
        </div>
      )}
      {/* ================================= */}
      {showAddressDiv && (
        <div style={{ height: '75vh', overflowY: 'auto' }}>
          <AddressComponent1 hideAddressForm={updateInExistingAddress} setAddressDiv={setAddressDiv} />
        </div>
      )}
      {/* ============================== */}

      {/* Adding payment option page */}
      {showPaymentDiv && (
        <div className="row m-0 flex-row flex-column" style={{ height: '80vh', }}>
          <div className="col-12" style={{ borderBottom: '1px solid #ddd' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px', margin: '10px 0px' }}>
              <AiOutlineArrowLeft style={{ cursor: 'pointer' }} onClick={onClickhandlePaymentPage} />
              <p className="text-center m-0">Select Payment Card</p>
              <span />
            </div>
            {!isNewCardFormOpen && (
              <div style={{ width: '100%', height: '30px', textAlign: 'right' }}>
                <Button type="button" onClick={addNewCard} color="primary">Add New Card</Button>
              </div>
            )}
          </div>
          {!isNewCardFormOpen && (
            <div style={{ height: '400px', overflowY: 'auto' }}>
              {cardLists && cardLists.length > 0 && cardLists.map((item) => (
                <div className={`col-12 px-1 py-1 ${styles.cardListdata}`} key={item.id}>
                  <div style={{ width: '17%' }}>
                    <Radio
                      checked={item.isDefault}
                      onClick={() => handleCardSelection(item)}
                      value={item.id}
                      name="cardButtons"
                      id={`rad_${item.id}`}
                    />
                  </div>
                  <div htmlFor={`rad_${item.id}`} style={{ width: '83%' }} className={styles.svgColor}>
                    <h4 style={{ fontSize: '16px', marginBottom: '0px' }}>
                      {item.brand}
                      <AiOutlineDelete style={{ float: 'right', cursor: 'pointer' }} onClick={() => deleteCard(item)} />
                    </h4>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <span>{`xxxx xxxx xxxx ${item.last4}`}</span>
                      <span>
                        {item.exp_month.length === 2 ? item.exp_month : `0${item.exp_month}`}
                        /
                        {item.exp_year}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {isNewCardFormOpen && (
            <div className="col-12 py-1" style={{ height: '400px', overflowY: 'auto' }}>
              <StripePaymentComponent token={token} userId={userId} getCardData={setActiveCard} />
            </div>
          )}
          {cardLists.length === 0 && !isNewCardFormOpen && (
          <h4>No Card has been added</h4>
          )}
        </div>
      )}
    </div>
  );
};

BuyNowCheckoutComponent.propTypes = {
  hidecartBlock: PropTypes.func.isRequired,
  cartItems: PropTypes.array,
  userId: PropTypes.string,
  token: PropTypes.string,
  allAddress: PropTypes.array,
  backtoCartSummary: PropTypes.func,
  clearCart: PropTypes.func,
  stripeCustomerId: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  cartItems: state.cart.cartItems,
  userId: state.user.currentUser,
  allAddress: state.user.addressList,
  stripeCustomerId: state.user.stripeCustomerId
});

export default connect(mapStateToProps, { clearCart })(BuyNowCheckoutComponent);
