/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { Player } from 'video-react';
import { AiFillEye, AiFillHeart, AiOutlineExpand, AiOutlineShop } from 'react-icons/ai';
// import { MdOutlineCampaign } from 'react-icons/md';
import { IoIosChatbubbles, IoIosShareAlt } from 'react-icons/io';
import { BsFillCartFill, BsVolumeUp } from 'react-icons/bs';
import { IoExpandOutline } from 'react-icons/io5';
import { FiVolumeX } from 'react-icons/fi';
import Badge from '@mui/material/Badge';
import { Navigate, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
// import ReactHlsPlayer from 'react-hls-player';
import { toast } from 'react-toastify';
import LiweShowsCard from '../liweShowsList';
import ProductListComponent from '../productList';
import { getStoreDataStart } from '../../redux/liweShowsList/list.actions';
import ShareUrlDialog from '../../components/dialog-box/share-url-dialog';
import styles from '../home/home.module.scss';
import Chat from '../../components/chat-component/chat';
import dummy from '../../assets/icons/dummy-image.jpg';
import { GET_WEBINAR_DETAILS, GET_CHAT_HISTORY } from '../../utils/consts/api';
import axios from '../../utils/config/http';
// import IndividualProductDetail from '../productList/product-details';
import AllProductComponent from '../productList/all-product-list';
import CartSummaryPage from '../summary-cart';
import AuthComponent from '../../components/Auth';
import LoaderComponent from '../../components/loader-component';
import CurrentActiveProduct from '../productList/current-active-poroduct';
import { Memoized } from '../../components/video-player';
import { getCartItems } from '../../redux/cart/cart.actions';
import { SocketContext } from '../../components/socket/Socket';

// eslint-disable-next-line no-shadow
const StoreComponent = ({ userId, userName, getStoreDataStart, userStatus, getCartItems, cartItems, storeName, merchantImage, selectedWebinar, errorMerchant, message, productVideoId }) => {
  const statedata = useSelector((state) => state);
  const [videoLayout, setVideoLayout] = useState({
    width: '100%',
    height: '450px',
    containerWidth: '30%',
    gapTop: '450px'
  });
  const [isExpanded, setExpansion] = useState(false);
  const [isMuted, setVolume] = useState(true);
  const [open, setDialogOpen] = useState(false);
  const [isWebinarDetailsLoaded, setIsWebinarDetails] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [mainPageView, setmainPageView] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentVideoTime, getCurrentVideoTime] = useState(0);
  const [isShowCartOpen, setIsCartOpen] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [showVideoBasedproductDetail, setShowVideoBasedProdDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showDiv, setDiv] = useState(false);
  const [prodList, setCurrentProductList] = useState(null);
  const [isAllProductsOpen, setShowProductDetail] = useState(false);
  const [chatDataList, setChatDataList] = useState([]);
  const likesCount = useRef(0);
  const totalViews = useRef(0);
  const socket = useContext(SocketContext);
  const currentWebinarTime = useRef('');
  const [heartAnime, setHeartAnime] = useState(false);

  const checkIfMobile = () => {
    const becameMobile = window.innerWidth <= 768;

    if (isMobile !== becameMobile) {
      setIsMobile(becameMobile);
    }
  };
  // let i = 1;
  let tempId = 0;
  const { storeId } = useParams();

  useEffect(() => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      getCartItems();
    }
  }, [userStatus]);

  useEffect(() => {
    if (!isWebinarDetailsLoaded && !selectedWebinar) {
      getStoreDataStart(storeId);
      setShowChat(true);
    }
    setIsWebinarDetails(true);
    if (selectedWebinar && selectedWebinar !== tempId) {
      setShowLoader(true);
      axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
        if (response.data.responseCode === 0) {
          if (response.data.response.webinarStatus === 3 || response.data.response.webinarStatus === 4) {
            setCurrentVideo(`${'https://rz7yircefedxbl.data.mediastore.us-east-1.amazonaws.com/liweshop/'}${selectedWebinar}${'.m3u8'}`);
          }

          if (response.data.response.webinarStatus === 5) {
            setCurrentVideo(response.data.response.mediaPkgStreamUrl);
            // setShowChat(true);
          }

          if (response.data.response.webinarStatus === 1 || response.data.response.webinarStatus === 2) {
            setCurrentVideo(response.data.response.webinarTrailerVideo[0]);
          }
          setCurrentProductList(response.data.response.productDetails);
          likesCount.current = response.data.response.totalLikes;
          totalViews.current = response.data.response.totalViewers;
          const date = new Date(response.data.response.webinarStartTime * 1000);
          const newdate = date.toDateString().substring(4);
          const time = date.toTimeString().substring(0, 5);
          const timeString12hr = new Date(`1970-01-01T${time}Z`)
            .toLocaleTimeString('en-US',
              { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
          currentWebinarTime.current = `${newdate}  ${timeString12hr}`;
          setWebinarDetails(response.data.response);
          setShowLoader(false);
          tempId = selectedWebinar;
        } else {
          console.log('Webinar detail not found');
        }
      });
    }
  }, [selectedWebinar]);

  useEffect(() => {
    if (errorMerchant && message) {
      toast.error(message, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [errorMerchant]);

  useEffect(() => {
    if (productVideoId) {
      if (webinarDetails && webinarDetails.bookmarkProduct) {
        const d = webinarDetails.bookmarkProduct.find((item) => item.productId === productVideoId);
        if (d) {
          const video = document.getElementById('myVideo');
          if (video) {
            video.currentTime = d.streamingSeconds;
          }
        }
      }
    }
  }, [productVideoId]);

  useEffect(() => {
    const video = document.getElementById('myVideo');
    if (video) {
      video.play();
      // video.muted = true;
      setVolume(true);
    }
  }, [productVideoId]);

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
    // if (currentVideo) {
    //   const video = document.getElementById('myVideo');
    //   if (video) {
    //     getCurrentVideoTime(video.currentTime);
    //     console.log(video.currentTime);
    //   }
    // }
    const interval = setInterval(() => {
      const video = document.getElementById('myVideo');
      if (video) {
        getCurrentVideoTime(video.currentTime);
        // console.log(video.currentTime);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const showAllProducts = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
    setShowProductDetail(true);
  };

  const openCurrentProductDetail = () => {
    const video = document.getElementById('myVideo');
    getCurrentVideoTime(video.currentTime);
    setDiv(true);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(true);
  };

  const getProductListHide = () => {
    setDiv(false);
    setShowProductDetail(false);
  };
  const getLikesViews = () => {
    const tempdata = likesCount.current;
    axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
      if (response.data.responseCode === 0) {
        if (tempdata < response.data.response.totalLikes) {
          setHeartAnime(true);
        }
        likesCount.current = response.data.response.totalLikes;
        totalViews.current = response.data.response.totalViewers;
        // i++;
      } else {
        console.log('Webinar detail not found');
      }
    });
  };
  const onHeartClick = () => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      const userInfo = {
        webinarId: selectedWebinar,
        customerId: userId,
        name: userName
      };
      socket.emit('heartReaction', userInfo);
      getLikesViews();
    } else {
      setIsUserLoggedIn(false);
      setIsCartOpen(true);
      setHeartAnime(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socket.on(`${selectedWebinar}_pubsub`, (resp) => {
          if (resp.eventName === 'numberOfLikes') {
            likesCount.current = resp.numberOfLikes;
          }
          console.log(resp, 'resp');
          if (resp.eventName === 'totalViewers') {
            totalViews.current = resp.totalViewers;
          }
          // totalViewers
        });
      }
    }, 5000);
  }, [selectedWebinar]);

  useEffect(() => {
    if (selectedWebinar) {
      axios.get(`${GET_CHAT_HISTORY}${selectedWebinar}/page/0/size/50`).then((response) => {
        setChatDataList(response.data.response.chatList);
        // if (chatList.length > 0) {
        //   const objDiv = document.getElementById('messageBox');
        //   objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
        // }
      }, (error) => {
        console.log(error);
      });
    }
  }, [selectedWebinar]);

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const changeExpansion = () => {
    setExpansion(!isExpanded);
    setmainPageView(!isExpanded);
    const video = document.getElementById('myVideo');
    video.play();
    setTimeout(() => {
      getCurrentVideoTime(video.currentTime);
    }, 1000);
    if (!isExpanded) {
      setVideoLayout({
        width: '100%',
        height: '94vh',
        containerWidth: '53%',
        gapTop: '630px'
      });
    } else {
      setVideoLayout({
        width: '100%',
        height: '450px',
        containerWidth: '30%',
        gapTop: '450px'
      });
    }
  };

  const changeExpansionMain = () => {
    if (!isExpanded) {
      setExpansion(!isExpanded);
      setmainPageView(!isExpanded);
      const video = document.getElementById('myVideo');
      video.play();
      setTimeout(() => {
        getCurrentVideoTime(video.currentTime);
      }, 1000);
      if (!isExpanded) {
        setVideoLayout({
          width: '100%',
          height: '94vh',
          containerWidth: '53%',
          gapTop: '630px'
        });
      } else {
        setVideoLayout({
          width: '100%',
          height: '450px',
          containerWidth: '30%',
          gapTop: '450px'
        });
      }
    }
  };

  const openShareDialog = (e) => {
    e.preventDefault();
    setDialogOpen(true);
  };

  const changeSound = () => {
    setVolume(!isMuted);
    const video = document.getElementById('myVideo');
    video.muted = !video.muted;
  };

  const handleShowChart = () => {
    const d = !showChat;
    setShowChat(d);
  };

  const getCartOpen = () => {
    setIsCartOpen(true);
  };

  // console.log('state data', selectedWebinar, selectedProfileData);
  if (isMobile) {
    return (<Navigate to={`/mobile/store/${storeId}`} />);
  }

  const backToProductDetailsDiv = () => {
    // setIsUserLoggedIn(false);
    setIsCartOpen(false);
  };

  const hideProductList = () => {
    setIsCartOpen(false);
    setDiv(false);
    setShowProductDetail(false);
  };

  const getHideVideoProdDetail = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
  };

  return (
    <>
      {!showLoader && (
      <div style={{ overflowY: isExpanded ? 'hidden' : 'auto', height: isExpanded ? 'auto' : '89vh' }}>
        {webinarDetails && <div
          className={styles.homeblock}
          style={{
            height: videoLayout.height,
            background: webinarDetails.webinarImageList
              ? `url(${webinarDetails.webinarImageList[0]})` : '#eee',
            overflow: 'hidden',
            cursor: !isExpanded ? 'pointer' : 'default'
          }}
          onClick={!isExpanded ? changeExpansion : null}
        >
          <div className={styles.bgWrapper} style={{ height: '100%' }}>
            <div className={styles.videoBlock} style={{ width: videoLayout.containerWidth, height: '100%' }} onClick={changeExpansionMain}>
              <Memoized currentVideo={currentVideo} web={webinarDetails.webinarStatus !== 5} />
            </div>
            {/* main page view */}
            {currentVideo && !mainPageView && <div className={styles.mainPageView}>
              <div className={styles.expandIconDiv}>
                <ul>
                  <li className={styles.darkBg}>
                    {!isMuted && <BsVolumeUp data-tip="Mute" onClick={changeSound} />}
                    {isMuted && <FiVolumeX data-tip="UnMute" onClick={changeSound} />}
                  </li>
                  <li>
                    {/* {!isExpanded && <AiOutlineExpand data-tip="Expand" onClick={changeExpansion} />} */}
                    {isExpanded && <IoExpandOutline data-tip="Collapse" onClick={changeExpansion} />}
                    {!isExpanded && <button type="button" onClick={changeExpansion}>Shop Now</button>}
                  </li>
                </ul>
              </div>
              <div className={styles.webinarInfoTile}>
                {webinarDetails && <div>
                  <h5>{currentWebinarTime.current}</h5>
                  <h6>{webinarDetails.webinarShortDescription}</h6>
                </div>}
              </div>
            </div>}
            {/* ==================== */}
            {mainPageView && <div className={styles.chatBlock}>
              {selectedWebinar && showChat && <Chat webinarId={selectedWebinar} webInarStatus={webinarDetails.webinarStatus} chartLists={chatDataList} />}
            </div>}
            <div><AiFillHeart className={heartAnime ? styles.heartAnim : styles.opacityHeart} onAnimationEnd={() => setHeartAnime(false)} /></div>
            {currentVideo && mainPageView && <div className={`${styles.videoControls} ${styles.singleicon}`}>
              {/* <ul>
                <li>
                  <MdOutlineCampaign data-tip="Campaign" />
                </li>
              </ul> */}
            </div>}
            {currentVideo && mainPageView && <div className={styles.videoControls}>
              <ul>
                <li>
                  {!isExpanded && <AiOutlineExpand data-tip="Expand" onClick={changeExpansion} />}
                  {isExpanded && <IoExpandOutline data-tip="Collapse" onClick={changeExpansion} />}
                </li>
                <li>
                  {!isMuted && <BsVolumeUp data-tip="Unmute" onClick={changeSound} />}
                  {isMuted && <FiVolumeX data-tip="Mute" onClick={changeSound} />}
                </li>
                <li>
                  <Badge badgeContent={cartItems.length} color="error" onClick={getCartOpen}>
                    <BsFillCartFill data-tip="Cart" onClick={getCartOpen} />
                  </Badge>
                </li>
                <li><IoIosChatbubbles data-tip="Chat" onClick={handleShowChart} /></li>
                <li>
                  <AiOutlineShop data-tip="All Products" onClick={showAllProducts} />
                </li>
                <li>
                  <IoIosShareAlt data-tip="Share" onClick={openShareDialog} />
                </li>
                <li>
                  <AiFillHeart onClick={onHeartClick} />
                </li>
              </ul>
            </div>}
            {/* Profile div started */}
            {webinarDetails && <div className={styles.profileMainBlock}>
              <div className={styles.dataBlock}>
                <img className={`img-fluid ${styles.profileImage}`} src={merchantImage || dummy} onError={handleImageErr} alt="No Inage" />
                <div className={styles.profileBlock}>
                  <h6>{webinarDetails.webinarTitle}</h6>
                  <p>{storeName}</p>
                </div>
              </div>
              {mainPageView && <div className={`mt-2 ${styles.likesAndLiveuser}`}>
                <div className={styles.LiveUsers}>
                  <AiFillEye />
                          &nbsp;
                  <span style={{ marginTop: '-7px' }}>{totalViews.current ? totalViews.current : 50}</span>
                </div>
                        &nbsp;
                <div className={styles.totalLikes}>
                  <AiFillHeart />
                         &nbsp;
                  <span style={{ marginTop: '-7px' }}>{likesCount.current ? likesCount.current : 50}</span>
                </div>
                </div>}
            </div>}
            {/* ===============end ============= */}
            {/* product div started */}
            {prodList.length > 0 && mainPageView && !isAllProductsOpen && <div className={styles.proDuctListBlock}>
              {showDiv && showVideoBasedproductDetail && <div className={styles.commonFloatingBlock}>
                {/* <AiOutlineArrowLeft style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '9999', cursor: 'pointer' }} onClick={onClickTocloseCommonDiv} /> */}
                <ProductListComponent currentVideoTime={currentVideoTime} webinarDetail={webinarDetails} hideVideoProdDetail={getHideVideoProdDetail} />
              </div>}
              {!showDiv && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={styles.listMainBlock} style={{ cursor: 'pointer' }} onClick={openCurrentProductDetail}>
                  <CurrentActiveProduct webinarDetail={webinarDetails} currentVideoTime={currentVideoTime} />
                </div>
              </div>}
              {!showDiv && <button type="button" onClick={showAllProducts} className={`btn btn-sm ${styles.customButton}`}>See all</button>}
            </div>}
            {isAllProductsOpen && !showVideoBasedproductDetail && <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <AllProductComponent webinarDetails={webinarDetails} hideProductList={getProductListHide} />
              </div>
            </div>}
            {isShowCartOpen && isUserLogedIn && <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <CartSummaryPage hidecartBlock={hideProductList} />
              </div>
            </div>}
            {isShowCartOpen && !isUserLogedIn && <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <AuthComponent showProductDetails={backToProductDetailsDiv} />
              </div>
            </div>}
            {/* =============end========== */}
          </div>
        </div>}
        {!mainPageView && <div style={{ marginTop: '6vh' }}>
          {!statedata.list.loadInitial && <div className="row m-0">
            {statedata.list.upComingList && statedata.list.upComingList.length > 0 && <div className="col-12">
              <p className={styles.headings}>
                Upcoming
                {statedata.list.upComingList.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>}
              </p>
            </div>}
            {statedata.list.upComingList && statedata.list.upComingList.length > 0 && <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.upComingList.map((element, index) => (<LiweShowsCard className="cards-block" type={`${'upcoming'}`} count={index} item={element} />))}
              </div>
            </div>}

            {statedata.list.today && statedata.list.today.length > 0 && <div className="col-12 mt-3">
              <p className={styles.headings}>
                Today
                {statedata.list.today.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>}
              </p>
            </div>}
            {statedata.list.today && statedata.list.today.length > 0 && <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.today.map((element, index) => (<LiweShowsCard type={`${'today'}`} count={index} item={element} />))}
              </div>
            </div>}

            {statedata.list.vod && statedata.list.vod.length > 0 && <div className="col-12 mt-3">
              <p className={styles.headings}>
                Recorded Live
                {statedata.list.vod.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>}
              </p>
            </div>}
            {statedata.list.vod && statedata.list.vod.length > 0 && <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.vod.map((element, index) => (<LiweShowsCard type={`${'vod'}`} count={index} item={element} />))}
              </div>
            </div>}

          </div>}
        </div>}
      </div>
      )}
      {showLoader && <div style={{ height: '100hv' }}>
        <LoaderComponent />
      </div>}
      <ReactTooltip />
      <ShareUrlDialog
        open={open}
        onDisagree={() => setDialogOpen(false)}
        url={selectedWebinar}
      />
    </>
  );
};

StoreComponent.propTypes = {
  getStoreDataStart: PropTypes.func.isRequired,
  userStatus: PropTypes.number,
  storeName: PropTypes.string,
  merchantImage: PropTypes.string,
  selectedWebinar: PropTypes.string,
  errorMerchant: PropTypes.bool,
  message: PropTypes.string,
  productVideoId: PropTypes.string,
  getCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  userId: PropTypes.string,
  userName: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  storeName: state.list.merchantStoreName,
  merchantImage: state.list.merchantImage,
  selectedWebinar: state.list.liweShowId,
  errorMerchant: state.list.errorMerchant,
  message: state.list.message,
  productVideoId: state.list.productVideoId,
  cartItems: state.cart.cartItems,
  userId: state.user.currentUser,
  userName: state.user.userName,
});

export default connect(mapStateToProps, { getStoreDataStart, getCartItems })(StoreComponent);
