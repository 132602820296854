import React, { useEffect } from 'react';
import styles from './terms.module.scss';

const PrivacypolicyComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 my-4">
          <h1 className={styles.termsHeading}>BOTIGA PRIVACY POLICY </h1>
          <div>
            <p>
              Thank you for visiting the Botiga website (the “Site”) and/or the Botiga mobile application (the “App”, and Site, separately or together, the “Platform”), managed by Botiga Live, Inc. (“Botiga”, “we”, or “us”).  Please read the following information carefully. It tells a reader when this Privacy Policy (or “Policy”) is effective, what information we collect from the visitors of our Site and/or users of the App (“Users” or “you”), how we might use it, how you can review or change the personal information you have provided to us, how we may change this Policy, and the steps we take to ensure that your personal information is protected. We are committed to the privacy of our Users and hope you enjoy your visit!
            </p>
            <p>
              This Policy will remain in effect until a new privacy policy or other notice supersedes it. We may choose to update this Policy at our discretion, so you should check this page periodically as the terms included in it may change from time to time. The effective date of the most recent version of this Privacy Policy will be reflected by the “last updated” date noted at the bottom of this page. Your continued use of the Platform will constitute your acknowledgment of the Policy in its current version and your agreement to and understanding of the terms included in this Policy.
            </p>
            <h5>If you have a specific question, feel free to skip to the appropriate section below: </h5>
            <ol>
              <li>What information is collected? </li>
              <li>How we use this information? </li>
              <li>How does our Platform interact with third party services and content? </li>
              <li>What information do we share with third parties (or what information may they access because of the services they provide to us)? </li>
              <li>What choices do you have over how your information is used? </li>
              <li>How can you edit or delete your information? </li>
              <li>Does our Platform respond to do-not-track signals? </li>
              <li>How do we secure your information on our Platform? </li>
              <li>What age do I have to be to use this Platform? </li>
              <li>What governing law applies? </li>
              <li>What if I live outside of the United States? </li>
              <li>Who should I contact with questions or concerns? </li>
              <li>When was this Policy last updated? </li>
            </ol>
          </div>
          <div>
            <h3 className={styles.subHeading}>1. What information is collected? </h3>
            <p>We collect two types of information: “Identifying Information” and “Non-Identifying Information”. </p>
            <h4>Types of Identifying Information we collect. </h4>
            <p>When you create an account, make a purchase, or when you enter information on this Platform, we may collect personal information (“Personal Information”) from you including your: </p>
            <ul>
              <li>First and last name; </li>
              <li>Email address; </li>
              <li>Phone numbers; </li>
              <li>Billing and shipping addresses; </li>
              <li>Payment information; </li>
              <li>Date of birth; </li>
              <li>Order history;  </li>
              <li>Account name and password; </li>
              <li>Screen name; </li>
              <li>Driver’s license number or identification card number; </li>
              <li>Gender; and </li>
              <li>Location information. </li>
            </ul>
            <h4>Types of Non-Identifying Information we collect. </h4>
            <p>We may also collect general information resulting from your activity on the Platform, including your preferences and other tendencies. This may include anonymous “traffic data” or tracking information provided by the Platform’s host or similar provider of such information (i.e., Google Analytics) that does not personally identify you, but may be helpful for marketing purposes or for improving the services we offer. “Analytics” is the process of collecting, analyzing, and reporting aggregate data. This information may include which pages Users visit, how long Users may stay on a particular page, and where Users are located.  It may also provide information about what browser, network, or device is used to access our Platform. To learn more about Google Analytics and how to opt out, please visit https://policies.google.com/privacy.  </p>
            <p>In addition, when you access our Platform, we may also log your anonymous IP addresses, a unique identifier for your computer or other electronic device. This helps us diagnose problems with our server, to administer our Platform, or to display the content according to your preferences. </p>
            <h4>We also use cookies and web beacons. </h4>
            <p>A “cookie” is a small string of information that a website you may visit transfers to your computer for identification purposes. Cookies can be used to follow your activity on the Site and that information helps the Site to understand your preferences and improve your Site experience. Cookies are also used for such activities as remembering your username and password, if you use such a feature on this Platform. You can turn off all cookies in the event you prefer not to receive them from the Site. You can also have your computer warn you whenever cookies are being used. There are also software products available that can manage cookies for you. Please be aware, however, that when you choose to reject cookies, this choice may limit the functionality of our Site and you may lose access to some of its features.</p>
            <p>A “web beacon” is an invisible pixel-sized graphic image on a web page, web-based document or e-mail message. It helps us do things like view the URL of the page on which the beacon appears and the time the Platform, document or email in question is viewed. They can be used to confirm the receipt of, and response to, our emails, including those that you forward to friends and family; and they help deliver a more personalized online experience. </p>
            <h4>A note regarding location information. </h4>
            <p>We receive location information when you’re using the Platform, including when the App is in use in the foreground or background. We may continuously collect location information and other motion-generated or orientation-generated mobile sensor data from you when the Platform is in use in the foreground or background in order to personalize the App experience, assist in finding available markets and/or available purchasing opportunities, offer purchasing incentives, track the progress and completion of purchases, and other similar purposes. We also use this information for Analytics purposes. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>2. How we use this information? </h3>
            <p>We and our third-party service providers may use your information and any third-party Personal Information or other data that you may provide, as applicable, to: </p>
            <ul>
              <li>Create and manage your account; </li>
              <li>Fulfill your requests (e.g., to allow you to order products through our merchants and have it delivered to you); </li>
              <li>Fulfill your purchases (e.g., to process payments); </li>
              <li>Customize content on our Platform (e.g., the stores that you can purchase from); </li>
              <li>Send you important information regarding the Platform, such as certain changes to our terms, conditions, policies and/or other administrative information; </li>
              <li>Improve our services and the interactions you have with our Platform; </li>
              <li>Provide customer service, whether it’s responding to a request or following up on an order; </li>
              <li>Inform you of and market products, programs, and services that we think may be of interest to you, and permit you to participate in sweepstakes, contests and similar promotions–for instance, we may add you to the Botiga email list and newsletter when you make an online purchase or sign up for our internal business purposes, such as data analysis, audits, developing new products, enhancing the Platform, improving our services, identifying usage trends and determining the effectiveness of our promotional campaigns; </li>
              <li>Contact you if you have signed up for one of our mobile messaging programs, as described in our Terms of Use; and/or</li>
              <li>Comply with and enforce applicable legal requirements, relevant industry standards and our policies. </li>
            </ul>

          </div>
          <div>
            <h3 className={styles.subHeading}>3. How does our Platform interact with third-party services and content? </h3>
            <p>For your convenience, we may link to third-party sites and services, or otherwise display third-party content through our Platform. Here are some examples of third-party content and services available through or via our Platform: </p>
            <ul>
              <li>Marketing Service Providers– We may automate our marketing and newsletters that may be sent to you via email or text messaging, which may include Mailchimp, Klaviyo or other third-party email, SMS messaging and marketing service providers. </li>
              <li>YouTube or Vimeo – We may embed content on our Platform from other online services, such as a recorded video. </li>
              <li>Infrastructure – We use Amazon Web Services to support our Platform. </li>
              <li>Payment Processing – We work with third-party vendors to process payments. This includes buy may not be limited to Stripe. </li>
              <li>Delivery – We work with third-party vendors to deliver our products. These include but are not limited to: independent contractors, retail couriers and Shippo</li>
              <li>Ads – We work with third party vendors to deliver targeted advertising to our visitors.  These include, but are not limited to: Google AdSense, Apple iAd, Yahoo, Facebook, Instagram, and Twitter Ads. </li>

            </ul>
            <p>These links take you outside our service and off our Platform and are beyond our control. Please note that these other sites may send their own cookies to users, collect data, or solicit personal information. We urge you to review the equivalent data protection, privacy, and cookie policies available on their websites. We do not accept any responsibility or liability for the data protection of privacy practices of third parties in relation to such websites and your use of third party websites is entirely at your own risk. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>4. What information do we share with third parties (or what information may they access because of the services they provide to us)? </h3>
            <p>We will not sell, trade, or otherwise transfer your Personal Information or general information to third parties without informing you in advance. However, we may provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties. Our service partners may use your Personal Information to help us operate our Platform and deliver certain services. We use these third parties to help handle parts of our business. They help us do things like fulfill orders, process payments, provide Analytics, monitor activity, maintain databases, and administer and monitor emails. These parties will only use your data for the purpose of carrying out the work as agreed with us. </p>
            <h4>Disclosure of your information for security purposes. </h4>
            <p>Botiga fully cooperates with law enforcement agencies in identifying Users who may use our Platform for illegal activities. We will release personal or general information without your consent for security purposes, when required by law, or to prevent imminent harm to any person or entity. We will disclose personal or general information upon receipt of a court order or subpoena, or to cooperate with a law enforcement investigation, which may include responding to requests and court orders from jurisdictions outside the United States. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful, as determined in our sole discretion. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>5. What choices do you have over how your information is used? </h3>
            <ul>
              <li>Email Opt Out – Those who wish to opt-out from receiving email offers will have their address removed within 1-10 days of receipt of their request to opt-out or to unsubscribe at hello@botiga.live. If you have multiple email addresses you will need to opt-out for each address in order to be removed from our active database.  </li>
              <li>Messaging Opt-Out – If you have opted in to one of our mobile messaging programs and wish to discontinue participating in such program or no longer agree to the Botiga Terms of Use or Messaging Terms contained therein, you can reply STOP, END, CANCEL, UNSUBSCRIBE, or QUIT to any mobile message from Botiga in order to opt out of the program as further described in the Terms of Use. You may receive an additional mobile message confirming your decision to opt out. You understand and agree that the foregoing options are the only reasonable methods of opting out and that any other method of opting out, including, but not limited to, texting words other than those set forth above or verbally requesting one of our employees to remove you from our list, is not a reasonable means of opting out. </li>
              <li>Location information – You may have the ability to turn location-based services on and off by adjusting the settings of your Internet browser or mobile device or, for some devices, by revoking permission for our App to access your location information. </li>
              <li>Cookies and web beacons – Your browser may offer the ability to block or delete cookies from your device. Please follow your individual browser’s instructions on how to block and clear cookies. </li>
              <li>Account deletion – If you would like to delete your Botiga account, please contact us at hello@botiga.live with your request. If you choose to delete your account, we will delete it for you, but may retain your account information to collect any fees owed, resolve disputes, troubleshoot problems, analyze usage of the Platform, assist with any investigations, prevent fraud, enforce our Terms of Use, or take other actions as required or permitted by law and/or regulations. </li>
            </ul>
          </div>
          <div>
            <h3 className={styles.subHeading}>6. How can you edit or delete your information? </h3>
            <p>By logging into your account, you can delete credit card information, billing and shipping address information, your phone number, and email address. If you would like to permanently delete your account, you can email us at hello@botiga.live. Please note, your name, order history, and insurance account information cannot be deleted from our system. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>7. Does our Platform respond to do-not-track signals? </h3>
            <p>At this time our Platform does not recognize automated browser signals regarding tracking mechanisms, which may include ‘do-not-track’ instructions.  However, you can change your privacy preferences regarding the use of cookies and similar technologies through your browser. You may set your browser to accept all cookies, block certain cookies, require your consent before a cookie is placed in your browser, or block all cookies. Please note, blocking all cookies will affect your online experience and may prevent you from enjoying the full features offered by our Site. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>8. How do we secure your information on the Platform? </h3>
            <p>In order to protect both the Personal Information and the general information that we receive from you through the website, we have implemented various security measures. We will take all steps reasonably necessary to ensure that your data is treated securely.  All information you provide to us is stored in secure managed servers.   </p>
            <p>We are PCI compliant. In addition, our third-party payment processing vendors are also PCI compliant. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Platform, you are responsible for keeping this password confidential. We ask that you never share your password with anyone. </p>
            <p>While we implement the above security measures on our Platform, you should be aware that 100% security is not always possible and whenever you give out personal information online there is a risk that third parties may intercept and use that information. While we strive to protect your personal information and privacy, we cannot guarantee the security of any information you disclose online. By using this Platform, you expressly acknowledge and agree that we cannot guarantee the security of any data provided to or received by us through this Platform and that any personal information, general information, or other data or information received from you through the Platform is provided at your own risk. We are not liable for any damages arising out of, or in any way connect with, a third party’s unauthorized access to your information. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>9. What age do I have to be to use this Platform? </h3>
            <p>This Platform is not intended for use by people under 18 years of age. We will not knowingly collect information from minors in the United States or elsewhere with or without the consent of their parents or guardians. Personal registration information submitted by a person under the age of 18 will not be accepted. Any general information provided by a User under the age of 18 and gathered (for example, through the use of cookies) during his or her visit may be used as indicated in this). You may only order products if you are 18 years old or older. If you place an order, you promise that you are 18 years old or older. If you are under the age of 18 and would like to purchase a product which you are of legal age to purchase, your parent or guardian should place the order for you. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>10. What governing law applies? </h3>
            <p>This Policy and our privacy practices will be subject exclusively to the laws of the State of California within the United States of America. By using our Platform, you hereby irrevocably submit to the exclusive jurisdiction of the competent courts of California any claim or matter arising under or in connection with the Platform. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>11. What if I live outside the United States? </h3>
            <p>The Platform is hosted and operated entirely in the United States and is subject to United States law. Please do not do not provide us with any information or attempt to purchase our products if you live outside of the United States. If you are from a jurisdiction outside of the United States, please notify us so that we may take necessary action. We do not operate outside of the 48-contiguous states. This may include terminating your access to the Platform, your account and deleting your information. We are committed to resolving those issues, so if you have any questions about how we collect or use your information you may email us at hello@botiga.live. </p>
          </div>
          <div>
            <h3 className={styles.subHeading}>12. Who should I contact with questions or concerns? </h3>
            <p>If you have any questions or comments relating to the Botiga Live, our Platform or this Privacy Policy, send an email to hello@botiga.live. </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacypolicyComponent;
