/* eslint-disable import/no-cycle */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import UserActionTypes from './user.types';
import { API_LOGIN_USER, API_REGISTER_USER, CREATE_USER_NAME, API_VALIDATE_OTP_USER, API_SOCIAL_LOGIN_USER,
  API_GET_CUSTOMER_DETAIL, GET_ADDRESS } from '../../utils/consts/api';
import axios from '../../utils/config/http';
import {
  signInSuccess,
  signInFailure,
  // signOutSuccess,
  signOutFailure,
  emailSignupSuccess,
  emailSignupFailure,
  createUserNameError,
  createUserNameFailure,
  createUserNameSuccess,
  validateOtpSuccess,
  validateOtpFailure,
  googleSignInSuccess,
  getCustomerDetails,
  getCustomerDetailsSuccess,
  getCustomerAddressSuccess,
  getCustomerAddressFailure
} from './user.actions';

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
  try {
    const userRef = yield call(
      userAuth,
      additionalData
    );
    const userSnapshot = yield userRef.get();
    yield put(signInSuccess({ id: userSnapshot.id, ...userSnapshot.data() }));
  } catch (error) {
    yield put(signInFailure(error));
  }
}
const getLoginApi = async (data) => {
  try {
    const obj = {
      emailId: data.email,
      password: data.password
    };
    const apiCall = API_LOGIN_USER;
    const res = await axios.post(apiCall, obj);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const registerUser = async (payload) => {
  try {
    const obj = {
      emailId: payload.emailId,
      userName: payload.userName,
      password: payload.password,
      customerProfilePic: 'https://some_url'
    };
    const res = await axios.post(API_REGISTER_USER, obj);
    return res.data;
  } catch (err) {
    return err;
  }
};

const validateOtp = async (payload) => {
  try {
    const obj = {
      emailId: payload.email,
      otpNumber: payload.otpnumber
    };
    const res = await axios.post(API_VALIDATE_OTP_USER, obj);
    return res.data;
  } catch (err) {
    return err;
  }
};

export function* signInWithEmail({ payload }) {
  try {
    const response = yield call(getLoginApi, payload);
    if (response.responseCode === 0) {
      yield put(signInSuccess(response));
      yield put(getCustomerDetails());
    } else {
      yield put(signInFailure(response));
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signUpwithEmail({ payload }) {
  try {
    const response = yield call(registerUser, payload);
    if (response.responseCode === 0) {
      yield put(emailSignupSuccess(response));
    } else {
      yield put(emailSignupFailure(response));
    }
  } catch (error) {
    yield put(emailSignupFailure(error));
  }
}

export function* validateOtpEmail({ payload }) {
  try {
    const response = yield call(validateOtp, payload);
    if (response.responseCode === 0) {
      yield put(validateOtpSuccess(response));
    } else {
      yield put(validateOtpFailure(response));
    }
  } catch (error) {
    yield put(validateOtpFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = true;// yield getCurrentUser();
    if (!userAuth) return;
    // yield getSnapshotFromUserAuth(userAuth);
  } catch (error) {
    yield put(signInFailure(error));
  }
}

export function* signOut() {
  try {
    // yield auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    // yield put(signOutSuccess());
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  } catch (error) {
    yield put(signOutFailure(error));
  }
}

const getCreateUserName = async (data) => {
  try {
    const apiCall = `${CREATE_USER_NAME}${data}`;
    const res = await axios.get(apiCall);
    return res;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export function* createUserName({ payload }) {
  try {
    const response = yield call(getCreateUserName, payload);
    if (response.data.responseCode === 0) {
      yield put(createUserNameSuccess(response));
    } else {
      yield put(createUserNameFailure(response));
    }
  } catch (error) {
    yield put(createUserNameError(error));
  }
}

const getGoogleLoginApi = async (data) => {
  // console.log('social data', data);
  try {
    const apiCall = API_SOCIAL_LOGIN_USER;
    const res = await axios.post(apiCall, data);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* signInWithGoogle({ payload }) {
  try {
    const response = yield call(getGoogleLoginApi, payload);
    if (response.responseCode === 0) {
      yield put(googleSignInSuccess(response));
      yield put(getCustomerDetails());
    } else {
      yield put(signInFailure(response));
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

const getCustomerDetailAPI = async () => {
  try {
    const res = await axios.get(API_GET_CUSTOMER_DETAIL);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* getCustomerDetailSaga() {
  try {
    const response = yield call(getCustomerDetailAPI);
    if (response.responseCode === 0) {
      yield put(getCustomerDetailsSuccess(response));
    } else {
      yield put(signInFailure(response));
    }
  } catch (error) {
    yield put(signInFailure(error));
  }
}

const getCustomerAddressAPI = async () => {
  try {
    const res = await axios.get(GET_ADDRESS);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export function* getCustomerAddressList() {
  try {
    const response = yield call(getCustomerAddressAPI);
    if (response.responseCode === 0) {
      yield put(getCustomerAddressSuccess(response));
    } else {
      yield put(getCustomerAddressFailure(response));
    }
  } catch (error) {
    yield put(getCustomerAddressFailure(error));
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(UserActionTypes.GOOGLE_SIGN_IN_START, signInWithGoogle);
}

export function* onEmailSignupStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_UP_START, signUpwithEmail);
}

export function* onValidateEmail() {
  yield takeLatest(UserActionTypes.VALIDATE_OTP_START, validateOtpEmail);
}

export function* onEmailSignInStart() {
  yield takeLatest(UserActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onCreateUserName() {
  yield takeLatest(UserActionTypes.CREATE_USER_NAME, createUserName);
}

export function* initializeCustomerDetail() {
  yield takeLatest(UserActionTypes.GET_CUSTOMER_DETAILS, getCustomerDetailSaga);
}

export function* initiateCustomerAddressList() {
  yield takeLatest(UserActionTypes.GET_CUSTOMER_ADDRESS, getCustomerAddressList);
}

export function* userSagas() {
  yield all([
    call(onGoogleSignInStart),
    call(onEmailSignupStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutStart),
    call(onCreateUserName),
    call(onValidateEmail),
    call(initializeCustomerDetail),
    call(initiateCustomerAddressList)
  ]);
}
