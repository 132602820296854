/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styles from './active-product.module.scss';
import dummy from '../../assets/icons/dummy-image.jpg';

// eslint-disable-next-line no-shadow
const CurrentActiveProduct = ({ webinarDetail, currentVideoTime }) => {
  // console.log(currentVideoTime);
  const [prodList, setprodDetails] = useState([]);
  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const onClickToShowDetails = (productId) => {
    const b = [...webinarDetail.productDetails];
    const a = b.filter((item) => item.id === productId);
    // console.log(productId);
    if (a) {
      setprodDetails(a);
    }
  };

  useEffect(() => {
    if (webinarDetail.bookmarkProduct && webinarDetail.bookmarkProduct.length > 0) {
      const dta = webinarDetail.bookmarkProduct;
      // console.log(dta);
      if (dta.length > 1) {
        for (let i = 0; i < dta.length; i++) {
          if (dta[i] && currentVideoTime > dta[i].streamingSeconds && i === dta.length - 1) {
            onClickToShowDetails(dta[i].productId);
            break;
          } else if (dta[i] && dta[i].streamingSeconds <= currentVideoTime && currentVideoTime < dta[i + 1].streamingSeconds) {
            onClickToShowDetails(dta[i].productId);
            break;
          } else if (currentVideoTime <= dta[0].streamingSeconds) {
            onClickToShowDetails(dta[0].productId);
            break;
          }
        }
      } else {
        onClickToShowDetails(dta[0].id);
      }

      // if (webinarDetail.bookmarkProduct.length === 1) {
      //   onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      // } else if (webinarDetail.bookmarkProduct.length === 2) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 3) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 4) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 5) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[3].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[4].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[4].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // } else if (webinarDetail.bookmarkProduct.length === 6) {
      //   if (currentVideoTime > webinarDetail.bookmarkProduct[4].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[5].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[5].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[3].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[4].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[4].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[2].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[3].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[3].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[1].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[2].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[2].productId);
      //   } else if (currentVideoTime > webinarDetail.bookmarkProduct[0].streamingSeconds
      //     && currentVideoTime <= webinarDetail.bookmarkProduct[1].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[1].productId);
      //   } else if (currentVideoTime <= webinarDetail.bookmarkProduct[0].streamingSeconds) {
      //     onClickToShowDetails(webinarDetail.bookmarkProduct[0].productId);
      //   }
      // }
    } else if (webinarDetail.productDetails && webinarDetail.productDetails.length > 0) {
      onClickToShowDetails(webinarDetail.productDetails[0].id);
    }
  }, [currentVideoTime, webinarDetail]);

  return (
    <>
      {prodList && prodList.length > 0 && (
        <>
          <span className={`badge ${styles.custombadge}`}>{prodList[0] ? `${'$'} ${prodList[0].parameterList[0].price}` : 'no price'}</span>
          <img className={`img-fluid ${styles.prodImage}`} src={prodList[0] ? prodList[0].productImageList[0] : dummy} onError={handleImageErr} alt="No Inage" />
        </>
      )}
    </>
  );
};

CurrentActiveProduct.propTypes = {
  // token: PropTypes.string,
  webinarDetail: PropTypes.object,
  currentVideoTime: PropTypes.number
};

export default connect(null, null)(CurrentActiveProduct);
