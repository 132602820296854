import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// import { Player } from 'video-react';
import { AiOutlineShop, AiFillEye, AiFillHeart } from 'react-icons/ai';
import { IoIosChatbubbles, IoIosShareAlt } from 'react-icons/io';
import { BsFillCartFill, BsVolumeUp } from 'react-icons/bs';
// import { MdOutlineCampaign } from 'react-icons/md';
import { IoExpandOutline } from 'react-icons/io5';
import { Navigate, useParams } from 'react-router-dom';
import { FiVolumeX } from 'react-icons/fi';
import Badge from '@mui/material/Badge';
import { toast } from 'react-toastify';
import { getStoreDataStart } from '../../redux/liweShowsList/list.actions';
import ShareUrlDialog from '../../components/dialog-box/share-url-dialog';
import styles from './store.module.scss';
import LoaderComponent from '../../components/loader-component';
import ProductListComponent from '../productlist';
import { VideoSection } from '../../components/styled/Sections.styled';
import Chat from '../../components/chat-component/chat';
import dummy from '../../assets/icons/dummy-image.jpg';
import { GET_WEBINAR_DETAILS, GET_CHAT_HISTORY } from '../../utils/consts/api';
import axios from '../../utils/config/http';
import ProductCards from '../cards/cards';
import FooterMobileComponent from '../../components/layout/mobile-layout/footer';
import AllProductComponent from '../productlist/all-product-list';
import CartSummaryPage from '../summary-cart';
import AuthComponent from '../../components/Auth';
import { Memoized } from '../../components/video-player';
import CurrentActiveProduct from '../productlist/current-active-poroduct';
import { getCartItems } from '../../redux/cart/cart.actions';
import { SocketContext } from '../../components/socket/Socket';

// eslint-disable-next-line no-shadow
const MobileStoreComponent = ({ userId, userName, getStoreDataStart, userStatus, cartItems, getCartItems, storeName, merchantImage, selectedWebinar, errorMerchant, message, productVideoId }) => {
  const statedata = useSelector((state) => state);
  const [isExpanded, setExpansion] = useState(false);
  const [isMuted, setVolume] = useState(true);
  const [open, setDialogOpen] = useState(false);
  const [isWebinarDetailsLoaded, setIsWebinarDetails] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [showVideoBasedproductDetail, setShowVideoBasedProdDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [prodList, setCurrentProductList] = useState(null);
  const [currentVideoTime, getCurrentVideoTime] = useState(0);
  const [isShowCartOpen, setIsCartOpen] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  const [isAllProductsOpen, setShowProductDetail] = useState(false);
  const [chatDataList, setChatDataList] = useState([]);
  const [heartAnime, setHeartAnime] = useState(false);
  const socket = useContext(SocketContext);
  const likesCount = useRef(0);
  const totalViews = useRef(0);
  const isIosDevice = useRef(false);
  const currentWebinarTime = useRef('');
  // const [videoHeight, setVideoHeight] = useState('45vh');
  // const [chatHistory, setChatHIstory] = useState([]);

  const checkIfMobile = () => {
    const becameMobile = window.innerWidth <= 768;

    if (isMobile !== becameMobile) {
      setIsMobile(becameMobile);
    }
  };

  useEffect(() => {
    isIosDevice.current = ['iPhone'].indexOf(navigator.platform) >= 0;
  }, []);

  const [showDiv, setDiv] = useState(false);
  // let i = 1;
  let tempId = 0;
  const { storeId } = useParams();
  useEffect(() => {
    if (!isWebinarDetailsLoaded && !selectedWebinar) {
      getStoreDataStart(storeId);
    }
    setIsWebinarDetails(true);
    if (selectedWebinar && selectedWebinar !== tempId) {
      setShowLoader(true);
      axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
        if (response.data.responseCode === 0) {
          if (response.data.response.webinarStatus === 3 || response.data.response.webinarStatus === 4) {
            setCurrentVideo(`${'https://rz7yircefedxbl.data.mediastore.us-east-1.amazonaws.com/liweshop/'}${selectedWebinar}${'.m3u8'}`);
          }

          if (response.data.response.webinarStatus === 5) {
            setCurrentVideo(response.data.response.mediaPkgStreamUrl);
            // setShowChat(true);
          }

          if (response.data.response.webinarStatus === 1 || response.data.response.webinarStatus === 2) {
            setCurrentVideo(response.data.response.webinarTrailerVideo[0]);
          }
          setCurrentProductList(response.data.response.productDetails);
          likesCount.current = response.data.response.totalLikes;
          totalViews.current = response.data.response.totalViewers;
          const date = new Date(response.data.response.webinarStartTime * 1000);
          const newdate = date.toDateString().substring(4);
          const time = date.toTimeString().substring(0, 5);
          const timeString12hr = new Date(`1970-01-01T${time}Z`)
            .toLocaleTimeString('en-US',
              { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' });
          currentWebinarTime.current = `${newdate}  ${timeString12hr}`;
          setWebinarDetails(response.data.response);
          setShowLoader(false);
          tempId = selectedWebinar;
          // i++;
        } else {
          console.log('Webinar detail not found');
        }
      });
    }
    // window.addEventListener('resize', checkIfMobile);
  }, [selectedWebinar]);

  useEffect(() => {
    if (errorMerchant && message) {
      toast.error(message, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [errorMerchant]);

  useEffect(() => {
    window.addEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    if (productVideoId) {
      if (webinarDetails && webinarDetails.bookmarkProduct) {
        const d = webinarDetails.bookmarkProduct.find((item) => item.productId === productVideoId);
        if (d) {
          const video = document.getElementById('myVideo');
          if (video) {
            video.currentTime = d.streamingSeconds;
          }
        }
      }
    }
  }, [productVideoId]);

  useEffect(() => {
    const video = document.getElementById('myVideo');
    if (video) {
      video.play();
      // video.muted = true;
      setVolume(true);
    }
  }, [productVideoId]);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = document.getElementById('myVideo');
      if (video) {
        getCurrentVideoTime(video.currentTime);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      getCartItems();
      setShowChat(true);
    }
  }, [userStatus]);

  const getLikesViews = () => {
    const tempdata = likesCount.current;
    axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
      if (response.data.responseCode === 0) {
        if (tempdata < response.data.response.totalLikes) {
          setHeartAnime(true);
        }
        likesCount.current = response.data.response.totalLikes;
        totalViews.current = response.data.response.totalViewers;
        // i++;
      } else {
        console.log('Webinar detail not found');
      }
    });
  };

  const onHeartClick = () => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      const userInfo = {
        webinarId: selectedWebinar,
        customerId: userId,
        name: userName
      };
      socket.emit('heartReaction', userInfo);
      getLikesViews();
    } else {
      setIsUserLoggedIn(false);
      setIsCartOpen(true);
      setHeartAnime(false);
    }
  };
  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socket.on(`${selectedWebinar}_pubsub`, (resp) => {
          if (resp.eventName === 'numberOfLikes') {
            likesCount.current = resp.numberOfLikes;
            setHeartAnime(true);
          }
          if (resp.eventName === 'totalViewers') {
            totalViews.current = resp.totalViewers;
          }
          // totalViewers
        });
      }
    }, 5000);
  }, [selectedWebinar]);

  useEffect(() => {
    if (selectedWebinar) {
      axios.get(`${GET_CHAT_HISTORY}${selectedWebinar}/page/0/size/50`).then((response) => {
        setChatDataList(response.data.response.chatList);
        // if (chatList.length > 0) {
        //   const objDiv = document.getElementById('messageBox');
        //   objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
        // }
      }, (error) => {
        console.log(error);
      });
    }
  }, [selectedWebinar]);

  // const onClickToOpenCommonDiv = () => {
  //   setDiv(true);
  // };

  const onClickTocloseCommonDiv = () => {
    setDiv(false);
  };

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const changeExpansion = () => {
    setExpansion(!isExpanded);
    // setVolume(false);
    // if (!isExpanded) {
    //   // setVideoHeight('100vh');
    //   const vdo = document.getElementById('myVideo');
    //   if (vdo) {
    //     vdo.disablepictureinpicture = true;
    //     vdo.playsinline = true;
    //   }
    // }
  };

  const openShareDialog = () => {
    setDialogOpen(true);
  };

  const changeSound = () => {
    setVolume(!isMuted);
    const video = document.getElementById('myVideo');
    video.muted = !video.muted;
  };

  const changeExpansion1 = () => {
    if (isExpanded === false) {
      setExpansion(true);
    }
  };

  const handleShowChart = () => {
    const d = !showChat;
    setShowChat(d);
  };

  if (!isMobile) {
    return (<Navigate to="/" />);
  }
  const getCartOpen = () => {
    setIsCartOpen(true);
  };
  const showAllProducts = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
    setShowProductDetail(true);
  };
  // const getProductListHide = () => {
  //   setDiv(false);
  //   setShowProductDetail(false);
  // };
  // const hideProductList = () => {
  //   setIsCartOpen(false);
  //   setDiv(false);
  //   setShowProductDetail(false);
  // };
  const backToProductDetailsDiv = () => {
    // setIsUserLoggedIn(false);
    setIsCartOpen(false);
  };
  // const getHideVideoProdDetail = () => {
  //   setDiv(false);
  //   setShowVideoBasedProdDetail(false);
  // };

  // const hideAllOpenwindow = () => {
  //   setDiv(false);
  //   setIsCartOpen(false);
  //   setShowProductDetail(false);
  // };

  const openCurrentProductDetail = () => {
    const video = document.getElementById('myVideo');
    getCurrentVideoTime(video.currentTime);
    setDiv(true);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(true);
  };

  const hideAllOpenSections = () => {
    setDiv(false);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(false);
    setIsCartOpen(false);
  };

  // const signOutFromApp = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   window.location.reload();
  // };

  return (
    <>
      {showLoader && <LoaderComponent />}
      {!showLoader && (
      <div className={!isExpanded && styles.scrollBlock}>
        {webinarDetails && (
        <div className={`${styles.homeblock} ${!isExpanded && styles.scrollBlock}`} onClick={changeExpansion1} onAnimationEnd={() => setHeartAnime(false)}>
          <VideoSection isExpanded={isExpanded} className={[styles.bgWrapper, !isExpanded ? styles.smallVdo : '']}>
            {/* <video id="vdtag" playsInline muted autoPlay src={webinarDetails && webinarDetails.webinarTrailerVideo[0] ? webinarDetails.webinarTrailerVideo[0] : vdoUrl} disablepictureinpicture controlsList="nofullscreen nodownload noremoteplayback noplaybackrate nopi" width="100%" height="100%" oncanplay="this.play()">
                Your browser does not support the video tag.
              </video> */}
            <Memoized currentVideo={currentVideo} web={webinarDetails.webinarStatus !== 5} />
            {webinarDetails && (
            <div className={styles.profileMainBlock}>
              <div className={styles.dataBlock}>
                <div className={styles.storeDetail}>
                  <img className={`img-fluid ${styles.profileImage}`} src={merchantImage || dummy} onError={handleImageErr} alt="No Inage" />
                  <div className={styles.profileBlock} onClick={changeExpansion}>
                    <h6>{webinarDetails.webinarTitle}</h6>
                    <p>{storeName}</p>
                  </div>
                  {!isExpanded && (
                  <div className={styles.minimumIcons}>
                    {!isMuted && <BsVolumeUp style={{ marginRight: '10px' }} onClick={changeSound} />}
                    {isMuted && <FiVolumeX style={{ marginRight: '10px' }} onClick={changeSound} />}
                    {/* {!isExpanded && <AiOutlineExpand onClick={changeExpansion} />} */}
                    {/* {!isExpanded && <button type="button" className="btn-custom-des" onClick={changeExpansion}>Shop Now</button>} */}
                  </div>
                  )}
                </div>
                {isExpanded && (
                  <div className={`mt-2 ${styles.likesAndLiveuser}`}>
                    <div className={styles.LiveUsers}>
                      <AiFillEye />
                    &nbsp;
                      <span style={{ marginTop: '-7px' }}>{totalViews.current ? totalViews.current : 50}</span>
                    </div>
                  &nbsp;
                    <div className={styles.totalLikes}>
                      <AiFillHeart />
                   &nbsp;
                      <span style={{ marginTop: '-7px' }}>{likesCount.current ? likesCount.current : 50}</span>
                    </div>
                  </div>
                )}
              </div>
              {isExpanded && (
              <div className={styles.campaignlayout}>
                {/* <MdOutlineCampaign /> */}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                <IoExpandOutline onClick={changeExpansion} />
              </div>
              )}
            </div>
            )}
            {!isExpanded && (
              <div className={styles.titleDescription}>
                <h6>{currentWebinarTime.current}</h6>
                <p>{webinarDetails.webinarShortDescription}</p>
              </div>
            )}
            {!isExpanded && (
            <div className={`${styles.minimumIcons} ${styles.manageTextBottom}`}>
              {!isExpanded && <button type="button" className="btn-custom-des" onClick={changeExpansion}>Shop Now</button>}
              {/* <AiOutlineExpand onClick={changeExpansion} /> */}
            </div>
            )}
            {isExpanded && (
              <>
                <div className={styles.hearts}>
                  <AiFillHeart className={heartAnime ? styles.heart : styles.opacityHeart} />
                </div>
                {showChat && (
                <div className={`${styles.chatBlock} ${isIosDevice.current && styles.chatBlockIos}`}>
                  {selectedWebinar && <Chat webinarId={selectedWebinar} webInarStatus={webinarDetails.webinarStatus} chartLists={chatDataList} />}
                </div>
                )}
                {currentVideo && (
                <div className={styles.videoControls} onAnimationEnd={() => setHeartAnime(false)}>
                  <ul>
                    <li>
                      {!isMuted && <BsVolumeUp onClick={changeSound} />}
                      {isMuted && <FiVolumeX onClick={changeSound} />}
                    </li>
                    <li><AiOutlineShop onClick={showAllProducts} /></li>
                    <li>
                      <Badge badgeContent={cartItems.length} color="error" onClick={getCartOpen}>
                        <BsFillCartFill data-tip="Cart" onClick={getCartOpen} />
                      </Badge>
                    </li>
                    <li><IoIosChatbubbles onClick={handleShowChart} /></li>
                    <li>
                      <IoIosShareAlt onClick={openShareDialog} />
                    </li>
                    <li>
                      <AiFillHeart onClick={onHeartClick} />
                    </li>
                    {/* {isUserLogedIn && (
                      <li>
                        <IoMdLogOut onClick={signOutFromApp} />
                      </li>
                    )} */}
                  </ul>
                </div>
                )}
                {prodList.length > 0 && (
                  <>
                    {showDiv && (
                      <div id="mobileScreen" className={`${styles.proDuctListBlock} ${styles.prodDetailsb} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                        {/* <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                          <h1>&nbsp;</h1>
                        </div> */}
                        <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                          <h1>&nbsp;</h1>
                        </div>
                        <div className={styles.commonFloatingBlock}>
                          <ProductListComponent currentVideoTime={currentVideoTime} webinarDetail={webinarDetails} />
                        </div>
                      </div>
                    )}
                    {!showDiv && (
                      <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos1}`}>
                        <div className={styles.webinarProduct}>
                          <div className={styles.listMainBlock} style={{ cursor: 'pointer' }} onClick={openCurrentProductDetail}>
                            <CurrentActiveProduct webinarDetail={webinarDetails} currentVideoTime={currentVideoTime} />
                          </div>
                          {/* {!showDiv && <div className={styles.customPlusProduct}><AiOutlinePlus /></div>} */}
                        </div>
                      </div>
                    )}
                  </>
                )}
                {isAllProductsOpen && !showVideoBasedproductDetail && (
                <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                  <div className={styles.showHideMark} onClick={onClickTocloseCommonDiv}>
                    <h1>&nbsp;</h1>
                  </div>
                  <div className={styles.commonFloatingBlock}>
                    <AllProductComponent webinarDetails={webinarDetails} />
                  </div>
                </div>
                )}
                {isShowCartOpen && isUserLogedIn && (
                <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                  <div className={styles.showHideMark} onClick={hideAllOpenSections}>
                    <h1>&nbsp;</h1>
                  </div>
                  <div className={styles.commonFloatingBlock}>
                    <CartSummaryPage hidecartBlock={hideAllOpenSections} />
                  </div>
                </div>
                )}
                {isShowCartOpen && !isUserLogedIn && (
                <div className={`${styles.proDuctListBlock} ${isIosDevice.current && styles.proDuctListBlockIos}`}>
                  <div className={styles.commonFloatingBlock}>
                    <AuthComponent showProductDetails={backToProductDetailsDiv} />
                  </div>
                </div>
                )}
                {(isShowCartOpen || isAllProductsOpen || showDiv) && (
                  <div className={styles.dummyClikableBox} onClick={hideAllOpenSections}>
                    &nbsp;
                  </div>
                )}
              </>
            )}
          </VideoSection>
        </div>
        )}
        {!isExpanded && (
        <div style={{ marginTop: isExpanded ? '100vh' : '5vh' }}>
          {!statedata.list.loadInitial && (
          <div className="row m-0">
            {statedata.list.upComingList && statedata.list.upComingList.length > 0 && (
            <div className="col-12">
              <p className={styles.headings}>
                Upcoming
                {/* {statedata.list.upComingList.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>} */}
              </p>
            </div>
            )}
            {statedata.list.upComingList && statedata.list.upComingList.length > 0 && (
            <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.upComingList.map((element, index) => (<ProductCards className="cards-block" type={`${'upcoming'}`} count={index} item={element} />))}
              </div>
            </div>
            )}

            {statedata.list.today && statedata.list.today.length > 0 && (
            <div className="col-12 mt-3">
              <p className={styles.headings}>
                Today
                {/* {statedata.list.today.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>} */}
              </p>
            </div>
            )}
            {statedata.list.today && statedata.list.today.length > 0 && (
            <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.today.map((element, index) => (<ProductCards type={`${'today'}`} count={index} item={element} />))}
              </div>
            </div>
            )}

            {statedata.list.vod && statedata.list.vod.length > 0 && (
            <div className="col-12 mt-3">
              <p className={styles.headings}>
                Recorded Live
                {/* {statedata.list.vod.length === 8 && <span className={`badge rounded-pill bg-danger ${styles.custombadge}`}>See all</span>} */}
              </p>
            </div>
            )}
            {statedata.list.vod && statedata.list.vod.length > 0 && (
            <div className="col-12">
              <div className={`${styles.listDiv} ${styles.manageScroller}`}>
                {statedata.list.vod.map((element, index) => (<ProductCards type={`${'vod'}`} count={index} item={element} />))}
              </div>
            </div>
            )}

          </div>
          )}
        </div>
        )}
        {!isExpanded && (
        <FooterMobileComponent />
        )}
      </div>
      )}
      <ShareUrlDialog
        open={open}
        onDisagree={() => setDialogOpen(false)}
        url={selectedWebinar}
      />
    </>
  );
};

MobileStoreComponent.propTypes = {
  getStoreDataStart: PropTypes.func.isRequired,
  userStatus: PropTypes.number,
  storeName: PropTypes.string,
  merchantImage: PropTypes.string,
  selectedWebinar: PropTypes.string,
  errorMerchant: PropTypes.bool,
  message: PropTypes.string,
  productVideoId: PropTypes.string,
  getCartItems: PropTypes.func,
  cartItems: PropTypes.array,
  userId: PropTypes.string,
  userName: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  storeName: state.list.merchantStoreName,
  merchantImage: state.list.merchantImage,
  selectedWebinar: state.list.liweShowId,
  errorMerchant: state.list.errorMerchant,
  message: state.list.message,
  productVideoId: state.list.productVideoId,
  cartItems: state.cart.cartItems,
  userId: state.user.currentUser,
  userName: state.user.userName,
});

export default connect(mapStateToProps, { getStoreDataStart, getCartItems })(MobileStoreComponent);
