/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import './login.scss';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { EMAIL_REGEX } from '../../utils/consts/api';
import { emailSignInStart, updateLoggedInError } from '../../redux/user/user.actions';

const LoginComponent = ({ emailSignInStart, isLoggedInFailed, errorMessage, updateLoggedInError }) => {
  // const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const { email, password } = inputs;
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (isLoggedInFailed && errorMessage && errorMessage.errorMsg) {
      toast.error(errorMessage.errorMsg, {
        position: 'top-right',
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      updateLoggedInError();
    }
  }, [isLoggedInFailed, errorMessage]);

  const validateEmail = () => {
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsValid(false);
        setErrorMsg('Invalid Email Address.');
      } else {
        setIsValid(true);
        setErrorMsg('');
      }
    } else {
      setIsValid(true);
      setErrorMsg('');
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (!email) {
      setIsValid(true);
      setErrorMsg('');
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email && password) {
      emailSignInStart(inputs);
    }
  };
  return (
    <>
      <form autoComplete="off">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <input type="email" className="form-control" autoComplete="false" placeholder="Enter email" name="email" value={email} required onChange={handleChange} onBlur={validateEmail} />
              {submitted && !email && (
              <div className="text-danger">
                Email is required
              </div>
              )}
              {!isValid && <div className="text-danger">{errorMsg}</div>}
            </div>
          </div>
          <div className="col-12">
            <div className="form-group">
              <input type="password" className="form-control" autoComplete="false" placeholder="Enter password" name="password" value={password} required onChange={handleChange} />
              {submitted && !password && (
              <div className="text-danger">
                Password is required
              </div>
              )}
            </div>
          </div>
          <div className="col-12">
            <button type="button" className="btn customButton" disabled={!email || !password || !isValid} onClick={handleSubmit}>Sign IN</button>
          </div>
        </div>
      </form>
    </>
  );
};

LoginComponent.propTypes = {
  emailSignInStart: PropTypes.func,
  isLoggedInFailed: PropTypes.bool,
  errorMessage: PropTypes.object,
  updateLoggedInError: PropTypes.func
};

const mapStateToProps = (state) => ({
  userId: state.user.currentUser,
  isLoggedInFailed: state.user.isLoggedInFailed,
  errorMessage: state.user.error
});

export default connect(mapStateToProps, { emailSignInStart, updateLoggedInError })(LoginComponent);
