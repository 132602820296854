/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { RiSendPlaneFill } from 'react-icons/ri';
import styles from './chat.module.scss';
import { SEND_CHAT_MESSAGE, NEW_USER, } from '../../utils/config/socket';
// import axios from '../../utils/config/http';
// import { GET_CHAT_HISTORY } from '../../utils/consts/api';
import person from '../../assets/icons/default-person.png';
import LoginRegisterDialog from '../login-register-dialog-box';
import { SocketContext } from '../socket/Socket';

const Chat = ({ isLoggedIn, userId, userName, userProfilePic, props }) => {
  const { webinarId, chartLists } = props;
  // webInarStatus
  const [state, setState] = useState({ message: '', name: '' });
  const [chatList, setChat] = useState(chartLists);
  const socket = useContext(SocketContext);

  const [openLoginRedg, setDialogOpenLOginRedg] = useState(false);

  useEffect(() => {
    if (webinarId) {
      const userInfo = {
        webinarId,
        customerId: userId,
        customerName: userName,
        customerProfileImage: userProfilePic
      };
      socket.emit(NEW_USER, userInfo, (data) => {
        console.log(data);
      });
      // socketCon.current.on(`${webinarId}_pubsub`, (response) => {
      //   console.log(response);
      //   console.log('kya hai re bhai.. chal ja');
      //   // return response;
      // });
      // console.log('im done');
    }
  }, [webinarId]);

  // const socketRef = useRef();
  useEffect(() => {
    if (chartLists.length > 0) {
      const objDiv = document.getElementById('messageBox');
      objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
    }
  }, [chartLists]);

  useEffect(() => {
    if (userId) {
      setDialogOpenLOginRedg(false);
    }
  }, [userId]);
  // console.log('testdda==============');
  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socket.on(`${webinarId}_pubsub`, (response) => {
          if (response.eventName === 'send-chat-message') {
            const obj = {
              chatId: '123123',
              customerId: userId,
              customerName: response.name,
              imageURL: person,
              message: response.message,
              webinarId
            };
            // console.log(chatList);
            const d = [...chatList];
            // console.log(d);
            d.push(obj);
            setChat(d);
          }
          // return response;
        });
      }
    }, 5000);
  }, []);

  const onTextChange = (e) => {
    if (isLoggedIn !== -1) {
      setState({ ...state, [e.target.name]: e.target.value });
      setDialogOpenLOginRedg(false);
    } else {
      setDialogOpenLOginRedg(true);
    }
  };

  // const handleLike = () => {
  //   setHeartAnime(true);
  //   if (isLoggedIn !== -1) {
  //     // setDialogOpenLOginRedg(false);
  //     const userInfo = {
  //       webinarId,
  //       customerId: userId,
  //       name: userName
  //     };
  //     if (socketCon.current.connected) {
  // socketCon.current.emit(HEART_REACTION, userInfo, (data) => {
  // console.log(data);
  // });
  //   } else {
  //     setHeartAnime(false);
  //     setDialogOpenLOginRedg(true);
  //   }
  // };
  const onMessageSubmit = (e) => {
    if (e.keyCode === 13) {
      const { name, message } = state;
      if (message) {
        const userInfo = {
          webinarId,
          customerId: userId,
          name: userName,
          imageURL: userProfilePic,
          message,
          isMerchantFlag: true
        };
        setChat((data) => [
          ...data,
          userInfo
        ]);
        // emitSocketEvent(SEND_CHAT_MESSAGE, userInfo);
        // console.log(socketCon.current);
        if (socket.connected) {
          socket.emit(SEND_CHAT_MESSAGE, userInfo, (data) => {
            console.log(data);
          });
          // socketCon.current.on('connection', (socket) => {
          //   console.log(socket);
          //   socket.emit(SEND_CHAT_MESSAGE, userInfo);
          // });
        }
        setState({ message: '', name });
      }
      e.preventDefault();
    }
  };

  const onClickSendSubmit = (e) => {
    e.preventDefault();
    const { name, message } = state;
    if (message) {
      const userInfo = {
        webinarId,
        customerId: userId,
        name: userName,
        imageURL: userProfilePic,
        message,
        isMerchantFlag: true
      };
      setChat((data) => [
        ...data,
        userInfo
      ]);
      if (socket.connected) {
        socket.emit(SEND_CHAT_MESSAGE, userInfo, (data) => {
          console.log(data);
        });
      }
      setState({ message: '', name });
    }
  };

  const handleImageErr = (e) => {
    e.target.src = person;
  };

  const renderChat = () => {
    return chatList.slice(0).reverse().map((item) => (
      <div className={styles.chatList}>
        <div className={styles.imgBlock}>
          <img src={item.imageURL ? item.imageURL : person} alt={item.customerName} onError={handleImageErr} />
        </div>
        <div className={styles.chatContent}>
          <h3>{item.customerName ? item.customerName : item.name}</h3>
          <p>{item.message}</p>
        </div>
      </div>
    ));
  };

  return (
    <>
      <div className={styles.chatBox}>
        <div className={styles.messageBox} id="messageBox">
          {renderChat()}
        </div>
        {/* show only when webinar is live and customer logged in */}
        {/* {(webInarStatus === 5) && userId && ( */}
        <div className={styles.messageInputBox}>
          {/* <button className="btn btn-default m-0" type="button" onClick={handleLike}><AiFillHeart /></button> */}
          <input onKeyDown={onMessageSubmit} className="form-control form-control-sm" type="text" placeholder="Say something..." name="message" value={state.message} onChange={(e) => onTextChange(e)} />
          <button type="button" onClick={onClickSendSubmit} className="btn"><RiSendPlaneFill /></button>
        </div>
        {/* )} */}
      </div>
      <LoginRegisterDialog
        open={openLoginRedg}
        onDisagree={() => setDialogOpenLOginRedg(false)}
      />
    </>
  );
};

Chat.propTypes = {
  userId: PropTypes.string,
  userName: PropTypes.string,
  userProfilePic: PropTypes.string
  // userEmail: PropTypes.string
};

const mapStateToProps = (state, props) => ({
  userId: state.user.currentUser,
  isLoggedIn: state.user.userStatus,
  userName: state.user.userName,
  userEmail: state.user.userEmail,
  userProfilePic: state.user.success && state.user.success.customerProfilePic ? state.user.success.customerProfilePic : null,
  props
});

export default connect(mapStateToProps, {})(Chat);
