import React, { Fragment, useEffect, useState, useContext, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { Player } from 'video-react';
import { AiFillEye, AiFillHeart, AiOutlineShop } from 'react-icons/ai';
import { IoIosChatbubbles, IoIosShareAlt } from 'react-icons/io';
import { IoExpandOutline } from 'react-icons/io5';
import { BsFillCartFill, BsVolumeUp } from 'react-icons/bs';
import { FiVolumeX } from 'react-icons/fi';
import { useParams, useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import ProductListComponent from '../productList';
import ShareUrlDialog from '../../components/dialog-box/share-url-dialog';
import styles from '../home/home.module.scss';
import Chat from '../../components/chat-component/chat';
import dummy from '../../assets/icons/dummy-image.jpg';
import { GET_WEBINAR_DETAILS, GET_CHAT_HISTORY } from '../../utils/consts/api';
import axios from '../../utils/config/http';
import AllProductComponent from '../productList/all-product-list';
import CartSummaryPage from '../summary-cart';
import AuthComponent from '../../components/Auth';
import LoaderComponent from '../../components/loader-component';
import { Memoized } from '../../components/video-player';
import CurrentActiveProduct from '../productList/current-active-poroduct';
import { SocketContext } from '../../components/socket/Socket';

// eslint-disable-next-line no-shadow
const WebinarDesktopComponent = ({ userStatus, userName, storeName, merchantImage, productVideoId, userId }) => {
  const videoLayout = {
    width: '100%',
    height: '91.6vh',
    containerWidth: '40%',
    gapTop: '450px'
  };
  const [isMuted, setVolume] = useState(true);
  const [open, setDialogOpen] = useState(false);
  const [webinarDetails, setWebinarDetails] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const [currentVideoTime, getCurrentVideoTime] = useState(0);
  const [isShowCartOpen, setIsCartOpen] = useState(false);
  const [isUserLogedIn, setIsUserLoggedIn] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [showVideoBasedproductDetail, setShowVideoBasedProdDetail] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [showDiv, setDiv] = useState(false);
  const [prodList, setCurrentProductList] = useState(null);
  const [isAllProductsOpen, setShowProductDetail] = useState(false);
  const [chatDataList, setChatDataList] = useState([]);
  const [heartAnime, setHeartAnime] = useState(false);
  const redirectUrl = useRef('');
  const likesCount = useRef(0);
  const totalViews = useRef(0);
  const socket = useContext(SocketContext);
  const { selectedWebinar } = useParams();
  const navigate = useNavigate();
  // let i = 1;
  let tempId = 0;

  useEffect(() => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
    }
  }, [userStatus]);

  useEffect(() => {
    if (selectedWebinar && selectedWebinar !== tempId) {
      setShowLoader(true);
      axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
        console.log(response, 'resp');
        if (response.data.responseCode === 0) {
          if (response.data.response.webinarStatus === 3 || response.data.response.webinarStatus === 4) {
            setCurrentVideo(`${'https://rz7yircefedxbl.data.mediastore.us-east-1.amazonaws.com/liweshop/'}${selectedWebinar}${'.m3u8'}`);
          }

          if (response.data.response.webinarStatus === 5) {
            setCurrentVideo(response.data.response.mediaPkgStreamUrl);
            setShowChat(true);
          }

          if (response.data.response.webinarStatus === 1 || response.data.response.webinarStatus === 2) {
            setCurrentVideo(response.data.response.webinarTrailerVideo[0]);
          }
          redirectUrl.current = response.data.response.merchantStoreName;
          setCurrentProductList(response.data.response.productDetails);
          setWebinarDetails(response.data.response);
          setShowLoader(false);
          tempId = selectedWebinar;
        } else {
          console.log('Webinar detail not found');
        }
      });
    }
  }, [selectedWebinar]);

  useEffect(() => {
    const interval = setInterval(() => {
      const video = document.getElementById('myVideo');
      if (video) {
        getCurrentVideoTime(video.currentTime);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (productVideoId) {
      if (webinarDetails && webinarDetails.bookmarkProduct) {
        const d = webinarDetails.bookmarkProduct.find((item) => item.productId === productVideoId);
        if (d) {
          const video = document.getElementById('myVideo');
          if (video) {
            video.currentTime = d.streamingSeconds;
          }
        }
      }
    }
  }, [productVideoId]);
  const getLikesViews = () => {
    const tempdata = likesCount.current;
    axios.get(GET_WEBINAR_DETAILS + selectedWebinar).then((response) => {
      if (response.data.responseCode === 0) {
        if (tempdata < response.data.response.totalLikes) {
          setHeartAnime(true);
        }
        likesCount.current = response.data.response.totalLikes;
        totalViews.current = response.data.response.totalViewers;
        // i++;
      } else {
        console.log('Webinar detail not found');
      }
    });
  };
  const onHeartClick = () => {
    if (userStatus === 1) {
      setIsUserLoggedIn(true);
      const userInfo = {
        webinarId: selectedWebinar,
        customerId: userId,
        name: userName
      };
      socket.emit('heartReaction', userInfo);
      getLikesViews();
    } else {
      setIsUserLoggedIn(false);
      setIsCartOpen(true);
      setHeartAnime(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (socket.connected) {
        socket.on(`${selectedWebinar}_pubsub`, (resp) => {
          if (resp.eventName === 'numberOfLikes') {
            likesCount.current = resp.numberOfLikes;
          }
          console.log(resp, 'resp');
          if (resp.eventName === 'totalViewers') {
            totalViews.current = resp.totalViewers;
          }
          // totalViewers
        });
      }
    }, 5000);
  }, [selectedWebinar]);

  useEffect(() => {
    if (selectedWebinar) {
      axios.get(`${GET_CHAT_HISTORY}${selectedWebinar}/page/0/size/50`).then((response) => {
        setChatDataList(response.data.response.chatList);
        // if (chatList.length > 0) {
        //   const objDiv = document.getElementById('messageBox');
        //   objDiv.scrollTop = objDiv.scrollHeight - objDiv.clientHeight;
        // }
      }, (error) => {
        console.log(error);
      });
    }
  }, [selectedWebinar]);

  useEffect(() => {
    const video = document.getElementById('myVideo');
    if (video) {
      video.play();
      video.muted = true;
      setVolume(true);
    }
  }, [productVideoId]);

  const showAllProducts = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
    setShowProductDetail(true);
  };

  const openCurrentProductDetail = () => {
    const video = document.getElementById('myVideo');
    getCurrentVideoTime(video.currentTime);
    setDiv(true);
    setShowProductDetail(false);
    setShowVideoBasedProdDetail(true);
  };

  const getProductListHide = () => {
    setDiv(false);
    setShowProductDetail(false);
  };

  const handleImageErr = (e) => {
    e.target.src = dummy;
  };

  const openShareDialog = () => {
    setDialogOpen(true);
  };

  const changeSound = () => {
    setVolume(!isMuted);
    const video = document.getElementById('myVideo');
    video.muted = !video.muted;
  };

  const handleShowChart = () => {
    const d = !showChat;
    setShowChat(d);
  };

  const getCartOpen = () => {
    setIsCartOpen(true);
  };

  const backToProductDetailsDiv = () => {
    // setIsUserLoggedIn(false);
    setIsCartOpen(false);
  };

  const hideProductList = () => {
    setIsCartOpen(false);
    setDiv(false);
    setShowProductDetail(false);
  };

  const getHideVideoProdDetail = () => {
    setDiv(false);
    setShowVideoBasedProdDetail(false);
  };

  const onClickExpand = () => {
    return navigate(`/store/${redirectUrl.current}`);
  };

  return (
    <>
      {!showLoader && (
      <>
        {webinarDetails && (
        <div
          className={styles.homeblock}
          style={{
            height: 'auto',
            background: webinarDetails.webinarImageList
              ? `url(${webinarDetails.webinarImageList[0]})` : '#eee',
            margin: '0px -12px'
          }}
        >
          <div className={styles.bgWrapper}>
            {currentVideo && (
            <div className={styles.videoBlock} style={{ width: videoLayout.containerWidth, height: videoLayout.height }}>
              <Memoized currentVideo={currentVideo} web={webinarDetails.webinarStatus !== 5} />
            </div>
            )}
            {/* ==================== */}
            <div className={styles.chatBlock}>
              {selectedWebinar && <Chat webinarId={selectedWebinar} webInarStatus={webinarDetails.webinarStatus} chartLists={chatDataList} />}
            </div>
            <div><AiFillHeart className={heartAnime ? styles.heartAnim : styles.opacityHeart} onAnimationEnd={() => setHeartAnime(false)} /></div>
            {currentVideo && (
            <div className={styles.videoControls}>
              <ul>
                <li>
                  <IoExpandOutline onClick={onClickExpand} />
                </li>
                <li>
                  {!isMuted && <BsVolumeUp data-tip="Unmute" onClick={changeSound} />}
                  {isMuted && <FiVolumeX data-tip="Mute" onClick={changeSound} />}
                </li>
                <li><BsFillCartFill data-tip="Cart" onClick={getCartOpen} /></li>
                <li><IoIosChatbubbles data-tip="Chat" onClick={handleShowChart} /></li>
                <li>
                  <AiOutlineShop data-tip="All Products" onClick={showAllProducts} />
                </li>
                <li>
                  <IoIosShareAlt data-tip="Share" onClick={openShareDialog} />
                </li>
                <li>
                  <AiFillHeart onClick={onHeartClick} />
                </li>
              </ul>
            </div>
            )}
            {/* Profile div started */}
            {webinarDetails && (
            <div className={styles.profileMainBlock}>
              <div className={styles.dataBlock}>
                <img className={`img-fluid ${styles.profileImage}`} src={merchantImage || dummy} onError={handleImageErr} alt="No Inage" />
                <div className={styles.profileBlock}>
                  <h6>{webinarDetails.webinarTitle}</h6>
                  <p>{storeName}</p>
                </div>
              </div>
              <div className={`mt-2 ${styles.likesAndLiveuser}`}>
                <div className={styles.LiveUsers}>
                  <AiFillEye />
                          &nbsp;
                  <span style={{ marginTop: '-7px' }}>{totalViews.current ? totalViews.current : 50}</span>
                </div>
                        &nbsp;
                <div className={styles.totalLikes}>
                  <AiFillHeart />
                         &nbsp;
                  <span style={{ marginTop: '-7px' }}>{likesCount.current ? likesCount.current : 50}</span>
                </div>
              </div>
            </div>
            )}
            {/* ===============end ============= */}
            {/* product div started */}
            {prodList.length > 0 && !isAllProductsOpen && (
            <div className={styles.proDuctListBlock}>
              {showDiv && showVideoBasedproductDetail && (
              <div className={styles.commonFloatingBlock}>
                {/* <AiOutlineArrowLeft style={{ position: 'absolute', left: '10px', top: '10px', zIndex: '9999', cursor: 'pointer' }} onClick={onClickTocloseCommonDiv} /> */}
                <ProductListComponent currentVideoTime={currentVideoTime} webinarDetail={webinarDetails} hideVideoProdDetail={getHideVideoProdDetail} />
              </div>
              )}
              {!showDiv && (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <div className={styles.listMainBlock} style={{ cursor: 'pointer' }} onClick={openCurrentProductDetail}>
                  <CurrentActiveProduct webinarDetail={webinarDetails} currentVideoTime={currentVideoTime} />
                </div>
              </div>
              )}
              {!showDiv && <button type="button" onClick={showAllProducts} className={`btn btn-sm ${styles.customButton}`}>See all</button>}
            </div>
            )}
            {isAllProductsOpen && !showVideoBasedproductDetail && (
            <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <AllProductComponent webinarDetails={webinarDetails} hideProductList={getProductListHide} />
              </div>
            </div>
            )}
            {isShowCartOpen && isUserLogedIn && (
            <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <CartSummaryPage hidecartBlock={hideProductList} />
              </div>
            </div>
            )}
            {isShowCartOpen && !isUserLogedIn && (
            <div className={styles.proDuctListBlock}>
              <div className={styles.commonFloatingBlock}>
                <AuthComponent showProductDetails={backToProductDetailsDiv} />
              </div>
            </div>
            )}
            {/* =============end========== */}
          </div>
        </div>
        )}
      </>
      )}
      {showLoader && (
      <div style={{ height: '100hv' }}>
        <LoaderComponent />
      </div>
      )}
      <ReactTooltip />
      <ShareUrlDialog
        open={open}
        onDisagree={() => setDialogOpen(false)}
        url={selectedWebinar}
      />
    </>
  );
};

WebinarDesktopComponent.propTypes = {
  userStatus: PropTypes.number,
  storeName: PropTypes.string,
  merchantImage: PropTypes.string,
  productVideoId: PropTypes.string,
  userId: PropTypes.string,
  userName: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  myProps: props,
  token: state.user.token,
  userStatus: state.user.userStatus,
  storeName: state.list.merchantStoreName,
  merchantImage: state.list.merchantImage,
  productVideoId: state.list.productVideoId,
  userId: state.user.currentUser,
  userName: state.user.userName,
});

export default connect(mapStateToProps, null)(WebinarDesktopComponent);
